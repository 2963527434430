<template>
  <v-app>
    <v-row class="mx-0 mt-0">
      <!-- TABLA DE VENTAS -->
      <v-col cols="12" md="">
        <v-card class="elevation-10">
          <v-card-title>
            <h2>Ventas</h2>
            <v-spacer></v-spacer>
            <!-- Parte de búsqueda de ventas -->
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" color="#35CDCD" outlined
              hide-details>
            </v-text-field>
            <!-- Aquí termina búsqueda de ventas -->
          </v-card-title>
          <!-- Tabla de CRUD para ventas -->
          <v-data-table :headers="headers" :items="formattedVentas()" :search="search" item-key="idCita">
            <template v-slot:[`item.costo_servicio`]="{ value }">
              <v-chip :color="getColor(value)">
                {{ value }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon size="25px" color="red" class="mx-1" @click.stop="confirmDeleteItemV(item)" item-key="idCita">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <!-- Fin del CRUD -->
          <v-dialog v-model="dialogAlert" max-width="500">
            <v-card>
              <v-card-title class="headline">Alerta</v-card-title>
              <v-card-text>
                {{ alertMessage }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#35CDCD" text @click="dialogAlert = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="390">
            <v-card>
              <v-card-title class="headline">Eliminar venta </v-card-title>
              <v-card-text>¿Está seguro que desea eliminar esta venta?</v-card-text>
              <v-card-text> NOTA: Se eliminarán todas las sesiones relacionadas a esta venta</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="dialogDelete = false">Cancelar</v-btn>
                <v-btn color="#35CDCD" text @click="fetchDeleteItemV">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
</template>

<script>

import axios from '../axios';
import { API_HOST } from '../utils/constants.js';

export default {
  name: "VentasView",
  data: () => ({
    dialog1: false,
    dialog2: false,
    dialogAlert: false,
    alertMessage: false,
    ventaAEliminar: null,
    menu: false,
    modal1: false,
    modal2: false,
    menu2: false,
    dialogDelete: false,
    ventas: [],
    fechaDesdeServidor: [],
    fechaFormateada: [],
    search: '',
    snackbar: false,
    snackbarText: '',
    timeout: 3000,

  }),

  methods: {
    formatearFecha(fechaDesdeServidor) {
      try {
        const fechasFormateadas = fechaDesdeServidor.map(fecha => fecha.split('T')[0]);
        this.fechaFormateada = fechasFormateadas;
      } catch (error) {
        error
        this.snackbarText = 'Error al formatear la fecha';
        this.snackbar = true;
      }
    },

    fetchVentas() {
      axios.get(`https://${API_HOST}/api/vista/ventas`)
        .then(response => {
          const data = response.data.body;
          if (Array.isArray(data)) {
            this.ventas = data;
            this.fechaDesdeServidor = this.ventas.map(venta => venta.fecha);
            this.formatearFecha(this.fechaDesdeServidor);
          } else {
            this.snackbarText = 'La respuesta de la API no es válida';
            this.snackbar = true;
          }
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al obtener los ventas';
          this.snackbar = true;
        });
    },

    fetchClienteCount() {
      axios.get(`https://${API_HOST}/api/vista/clientes/contar`)
        .then(response => {
          const totalClientes = response.data.totalClientes;

          // Verifica si se debe mostrar una alerta
          if (totalClientes % 50 === 0 && totalClientes > 0) {
            this.alertMessage = `¡Alerta! Se han registrado ${totalClientes} clientes.`;
            this.dialogAlert = true;
          }
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al obtener el conteo de clientes';
          this.snackbar = true;
        });
    },

    //CONFIRMACION DE ELIMINACION EN CASO DE DAR CLIC A UN CLIENTE POR ERROR
    confirmDeleteItemV(item) {
     //  console.log('ID a eliminar:', item.idCita);
      this.ventaAEliminar = item.idCita; // Asigna el ID correcto del registro que se va a eliminar
      this.dialogDelete = true; // Muestra el diálogo de confirmación de eliminación
    },

    //FUNCION PARA ELIMINAR CLIENTES
    fetchDeleteItemV() {
      // Verifica que this.ventaAEliminar tenga un valor válido antes de hacer la solicitud DELETE
      if (this.ventaAEliminar !== null && this.ventaAEliminar !== undefined) {
        axios.delete(`https://${API_HOST}/api/vista/ventas/${this.ventaAEliminar}`)
          .then(() => {
            this.fetchVentas(); // Actualiza la lista de ventas después de eliminar
            this.dialogDelete = false; // Cierra el diálogo de confirmación de eliminación
            this.ventaAEliminar = null; // Reinicia la variable
            this.snackbarText = 'Venta eliminado exitosamente';
            this.snackbar = true;
          })
          .catch(error => {
            error
            this.snackbarText = 'Error al eliminar el cliente';
            this.snackbar = true;
          });
      } else {
        this.snackbarText = 'ID a eliminar no válido';
        this.snackbar = true;
      }
    },


    formattedVentas() {
      return this.ventas.map(venta => ({
        ...venta,
        fecha: venta.fecha.substring(0, 10)  // Aquí asumimos que la fecha está en formato 'YYYY-MM-DD HH:mm:ss'
      }));
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    getColor(costo_servicio) {
      if (costo_servicio > 9900) return 'green'
      else if (costo_servicio > 5000) return 'orange'
      else return 'blue'
    },
  },

  created() {
    this.fetchVentas();
    this.fetchClienteCount();
    setInterval(this.fetchClienteCount, 60 * 1000); // Verifica cada minuto
  },


  computed: {

    headers() {
      return [
        { text: 'Nombre', value: 'nombre_cliente' },
        { text: 'Apellido', value: 'apellido_cliente' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Hora', value: 'hora' },
        { text: 'Servicio', value: 'nombre_servicio' },
        { text: 'Monto', value: 'costo_servicio' },
        { text: "Eliminar", value: "actions", sortable: false },
      ];
    },
  },

  filters: {
    formatDate(value) {
      if (!value) return '';
      const date = new Date(value);
      const day = date.getDate();
      const month = date.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por eso sumamos 1
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  },



};
</script>

<style>
/* Estilos específicos de ClientesView */
</style>
