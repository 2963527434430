<template>
    <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="logout">
                <v-icon color="white" v-bind="attrs" v-on="on">mdi-logout</v-icon>
            </v-btn>
        </template>
        <span>Cerrar Sesion</span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'CerrarSesion',
    methods: {
        logout() {
            // Eliminar token de autenticación del almacenamiento local
            sessionStorage.removeItem('token');

            // Redirigir a la página de inicio
            this.$router.push('/');
        }
    }
};
</script>

<style scoped></style>