<template>
  <v-app>
    <v-row class="mx-0 mt-0">
      <!-- Segunda tarjeta -->
      <v-col cols="12" md="3">
        <v-card class="elevation-10">
          <v-card-title>
            <h3>Acciones</h3>
          </v-card-title>
          <v-list>
            <!-- Iterar sobre las opciones -->
            <v-list-item v-for="(opcion, index) in opciones" :key="index"
              :class="opcion === opcionSeleccionada ? 'active' : ''"
              :disabled="opcion !== 'Clientes' && !selectedClientId" @click="seleccionarOpcion(opcion)">
              <v-divider></v-divider>
              <v-list-item-title v-if="opcion === 'Clientes'">
                {{ opcion }}
              </v-list-item-title>
              <v-list-item-title v-else>
                {{ opcion }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <!-- TABLA DE CLIENTES -->
      <v-col cols="12" md="9">
        <v-card class="elevation-10">
          <v-card-title v-if="opcionSeleccionada === 'Clientes'">
            <h2>Clientes</h2>
            <v-spacer></v-spacer>
            <!-- Parte de búsqueda de clientes -->
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" color="#35CDCD" outlined
              hide-details>
            </v-text-field>

            <v-spacer></v-spacer>
            <!-- NOTIFICACIÓN PARA CUANDO SE LOGRE LLEGAR A LA META DE REGISTRO DE CLIENTES = 50 POR RECEPCION -->
            <!-- Dialogo para mostrar alerta -->
            <!-- MENSAJE DE NOTIFICACION PARA CUANDO LA CUENTA DE RECEPCION LLEGUE A REGISTRAR 50 CLIENTES -->
            <template>
              <div v-if="roleUser === 'admin' && showNotificationButton" class="notification-icon">
                <v-icon color="grey" @click="openDialogNoti">mdi-message-badge</v-icon>
                <v-badge color="red" content="1"></v-badge>
              </div>
              <v-dialog v-model="dialogNoti" persistent max-width="600px">
                <v-card>
                  <v-card-title class="text-h5">Alerta!</v-card-title>
                  <v-card-text>
                    El usuario Recepcion a registrado ({{ this.totalContador }}) clientes nuevos!.
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="closeDialogNoti">Cerrar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <v-spacer></v-spacer>
            <div class="mb-4 mt-4">
              <v-dialog v-model="dialog" persistent max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="#35CDCD" dark v-bind="attrs" v-on="on">
                    Agregar Cliente
                  </v-btn>
                </template>

                <v-card @submit.prevent="guardarCliente">
                  <v-card-title color="#35CDCD">
                    <span class="text-h5">Agregar Cliente</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field label="Nombre" color="#35CDCD" outlined id="nombre" v-model="nombre" type="text"
                            required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field label="Primer Apellido" color="#35CDCD" outlined id="apellido"
                            v-model="apellido" type="text" required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field label="Segundo Apellido" color="#35CDCD" outlined id="apellido2"
                            v-model="apellido2" type="text" required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field label="Telefono" color="#35CDCD" outlined id="telefono" v-model="telefono"
                            min="1" type="number" required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" justify="center">
                          <v-text-field label="Fecha de Nacimiento" type="date" color="#35CDCD" outlined
                            id="fechadeNacimiento" v-model="fechadeNacimiento" required></v-text-field>
                        </v-col>
                        <!--<v-col cols="12" sm="6" md="6">
                          <v-text-field label="Direccion" color="#35CDCD" outlined id="colonia" v-model="colonia"
                            type="text"></v-text-field>
                        </v-col>-->
                        <v-col cols="12" sm="6" md="6">
                          <v-select :items="items1" item-text="title" outlined color="#35CDCD" item-value="value"
                            id="sexo" v-model="sexo" label="Sexo" solo required></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field label="INE" color="#35CDCD" outlined id="ine" v-model="ine" type="text"
                            required></v-text-field>
                        </v-col><v-col cols="12" sm="12" md="12">
                          <v-select :items="items2" item-text="title" outlined color="#35CDCD" item-value="value"
                            id="donde" v-model="donde" label="De donde viene:" solo required></v-select>
                        </v-col>
                      </v-row>
                    </v-container>


                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red" text @click="dialog = false">
                        Cerrar
                      </v-btn>
                      <v-btn color="#35CDCD" text @click="fetchAgregarCliente">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                  <!-- Dialogo para mostrar alerta -->
                  <v-dialog v-model="alertaformulario" max-width="400">
                    <v-card>
                      <v-card-text class="text-center pa-4">
                        <span class="headline">¡Alerta!</span>
                        <div style="margin-top: 30px;"> <!-- Espacio adicional entre el span y el párrafo -->
                          <p>Por favor, complete todos los campos obligatorios.</p>
                        </div>
                        <v-card-actions class="justify-center">
                          <v-btn color="#35CDCD" text @click="alertaformulario = false">Entendido</v-btn>
                        </v-card-actions>
                      </v-card-text>
                    </v-card>
                  </v-dialog>

                </v-card>

              </v-dialog>
            </div>
            <!-- Aquí termina búsqueda de clientes -->
          </v-card-title>
          <!--////////////////////////////////////////////////////////////////////////////////////////////////////////-->
          <!-- Tabla de CRUD para clientes -->
          <v-data-table v-if="opcionSeleccionada === 'Clientes'" :headers="filteredHeaders" :items="clientes"
            :search="search" item-key="idCliente" @click:row="openProfile">
            <template v-slot:[`item.actions`]="{ item }">

              <v-icon size="25px" color="#35CDCD" class="mx-1" @click.stop="editItem(item)" item-key="idCliente">
                mdi-pencil
              </v-icon>

              <v-icon v-if="roleUser !== 'standard'" size="25px" color="red" class="mx-1"
                @click.stop="confirmDeleteItem(item)" item-key="idCliente">
                mdi-delete
              </v-icon>
            </template>

            <template v-slot:[`item.details`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon size="25px" color="#35CDCD">
                      mdi-account-multiple-outline
                    </v-icon>
                  </span>
                </template>
                <span>
                  Creado por: {{ item.createdBy || 'N/A' }} el {{ formatDate(item.createdDate) || 'N/A' }}
                  <br>
                  Última modificación por: {{ item.lastModifiedBy || 'N/A' }} el {{
                    formatDate(item.lastModifiedDate) || 'N/A' }}
                </span>
              </v-tooltip>
            </template>
          </v-data-table>
          <!--////////////////////////////////////////////////////////////////////////////////////////////////////////-->
          <!-- Fin del CRUD -->


          <!-- Dialogo de Confirmación de Eliminación -->
          <v-dialog v-model="dialogDelete" max-width="390">
            <v-card>
              <v-card-title class="text-center">Eliminar cliente </v-card-title>
              <v-card-text>¿Estás seguro de que quieres eliminar este cliente?</v-card-text>
              <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn color="red" text @click="dialogDelete = false">Cancelar</v-btn>
                <v-btn color="#35CDCD" text @click="fetchDeleteItem">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogEdit" max-width="800">
            <v-card>
              <v-card-title color="#35CDCD">
                <span class="text-h5" color="#35CDCD">Editar Cliente</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field label="Nombre" outlined color="#35CDCD" v-model="infoCliente.nombre" type="text"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field label="Primer Apellido" outlined color="#35CDCD" v-model="infoCliente.apellido"
                        type="text" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field label="Segundo Apellido" outlined color="#35CDCD" v-model="infoCliente.apellido2"
                        type="text" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field label="Teléfono" outlined color="#35CDCD" v-model="infoCliente.telefono" min="1"
                        type="number" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field label="Fecha de Nacimiento" type="date" id="fechadeNacimiento" outlined
                        color="#35CDCD" v-model="infoCliente.fechadeNacimiento" required></v-text-field>
                    </v-col>
                    <!--<v-col cols="12" sm="6" md="6">
                      <v-text-field label="Direccion" color="#35CDCD" outlined v-model="infoCliente.colonia" type="text"
                        required></v-text-field>
                    </v-col>-->
                    <v-col cols="12" sm="6" md="6">
                      <v-select :items="items1" item-text="title" outlined color="#35CDCD" item-value="value"
                        v-model="infoCliente.sexo" label="Sexo" solo></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field label="INE" color="#35CDCD" outlined id="ine" v-model="infoCliente.ine" type="text"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select :items="items2" item-text="title" outlined id="donde" color="#35CDCD" item-value="value"
                        v-model="infoCliente.donde" label="De donde viene" solo></v-select>
                    </v-col>
                  </v-row>
                </v-container>


                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="dialogEdit = false">
                    Cerrar
                  </v-btn>
                  <v-btn type="submit" color="#35CDCD" text @click="fetchsaveItem">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card-text>

              <v-spacer></v-spacer>
              <!-- Dialogo para mostrar alerta -->
              <v-dialog v-model="alertaformulario" max-width="400">
                <v-card>
                  <v-card-text class="text-center pa-4">
                    <span class="headline">¡Alerta!</span>
                    <div style="margin-top: 30px;"> <!-- Espacio adicional entre el span y el párrafo -->
                      <p>Por favor, complete todos los campos obligatorios.</p>
                    </div>
                    <v-card-actions class="justify-center">
                      <v-btn color="#35CDCD" text @click="alertaformulario = false">Entendido</v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card>
          </v-dialog>


          <!-- SECCION PARA CADA UNO DE LOS CLIENTES -->
          <perfil v-if="opcionSeleccionada === 'Perfil'" :idCliente="selectedClientId" />
          <valoracion v-if="opcionSeleccionada === 'Valoracion'" :idCliente="selectedClientId" />
          <articulos v-if="opcionSeleccionada === 'Paquetes'" :idCliente="selectedClientId" />
        </v-card>
      </v-col>
      <!-- TERMINA TABLA DE CLIENTES -->
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
</template>

<script>
import Perfil from '@/views/PerfilView.vue';
import Valoracion from '@/views/ValoracionView.vue';
import Articulos from '@/views/ArticulosView.vue';
import { API_HOST } from '../utils/constants.js';

import axios from '../axios';

export default {
  components: {
    Perfil,
    Valoracion,
    Articulos,
  },

  data() {
    return {
      selectedOpcion: '',
      opciones: ['Clientes', 'Perfil', 'Valoracion', 'Paquetes'],
      opcionSeleccionada: 'Clientes',
      clienteSeleccionado: null,
      profileDialog: false,
      selectedClientId: null,
      activePicker: null,
      alertaformulario: false,
      active: [],
      open: [],
      date: null,
      menu: false,
      dialog: false,
      modal: false,
      menu2: false,
      drawer: false,
      items: [
        { title: "Agenda", icon: "mdi-calendar", route: "/agenda" },
        { title: "Clientes", icon: "mdi-account-group", route: "/clientes" },
        { title: "Cortes", icon: "mdi-scissors-cutting", route: "/cortes" },
        { title: "Gastos", icon: "mdi-currency-usd", route: "/gastos" },
        { title: "Inventario", icon: "mdi-archive", route: "/inventario" },
        { title: "Ventas", icon: "mdi-cash-register", route: "/ventas" },
      ],


      items1: [
        { title: "Mujer", value: 'Mujer' },
        { title: "Hombre", value: 'Hombre' },
        //{ title: "Otro", value: 'Otro' }
      ],

      items2: [
        { title: "Facebook", value: 'Facebook' },
        { title: "Instagram", value: 'Instagram' },
        { title: "Recomendado por paciente", value: 'Recomendado por paciente' },
        { title: "Recomendado por Empleado", value: 'Recomendado por Empleado' }
      ],

      dialogEdit: false,
      dialogDelete: false,
      clienteAEliminar: null,
      clienteAEditar: null,
      search: "",
      save: "",
      clientes: [],
      infoCliente: {
        nombre: '',
        apellido: '',
        apellido2: '',
        telefono: '',
        fechadeNacimiento: '',
        colonia: '',
        sexo: '',
        ine: '',
        donde: '',
      },
      fechaDesdeServidor: '',

      nombre: '',
      apellido: '',
      apellido2: '',
      telefono: '',
      fechadeNacimiento: '',
      colonia: '',
      sexo: '',
      ine: '',
      donde: '',

      snackbar: false,
      snackbarText: '',
      timeout: 3000,
      filteredHeaders: [],


      dialogNoti: false,
      roleUser: sessionStorage.getItem('role'), // Suponiendo que ya tienes el rol en localStorage
      showNotificationButton: false,
      totalContador: 0,

    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    opcionSeleccionada(newVal) {
      this.selectedOpcion = newVal;
    }
  },

  mounted() {
    this.selectedOpcion = this.opcionSeleccionada;
    this.applyRoleBasedHeaders();
    this.fetchContarVerificarMeta();
  },

  methods: {
    mostrarSnackbar(texto) {
      this.snackbarText = texto;
      this.snackbar = true;
    },
    //FUNCION PARA HACER QUE A LA HORA RESIVIDA DEL SERVIDOR SE LE QUITE LA HORA
    formatearFecha(fechaDesdeServidor) {
      try {
        const fechaParte = fechaDesdeServidor.split('T')[0];
        this.infoCliente.fechadeNacimiento = fechaParte;
      } catch (error) {
        error
        this.snackbarText = 'Error al formatear la fecha';
        this.snackbar = true;
      }
    },
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'N/A'; // Verifica si la fecha es inválida
      return date.toISOString().split('T')[0]; // 'YYYY-MM-DD'
    },

    //FUNCION PARA VER LOS CLIENTES EN LA TABLA
    fetchClientes() {
      axios.get(`https://${API_HOST}/api/vista/clientes`)
        .then(response => {
          let data = response.data.body;
          if (Array.isArray(data)) {
            this.clientes = data;
          } else {
            this.snackbarText = 'La respuesta de la API no es válida';
            this.snackbar = true;
          }
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al obtener los clientes';
          this.snackbar = true;
        });
    },

    //FUNCION PARA PODER VER LA INFO DEL CLIENTE SELECCIONADO EN TABLA
    openProfile(item) {
      this.selectedClientId = item.idCliente;
      this.opcionSeleccionada = 'Perfil';
      this.fetchContarVerificarMeta();
    },

    //FUNCION PARA FILTRAR LOS DATOS DE LOS CLIENTES EN LA TABLA
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    //FUNCION PARA AGREGAR CLIENTES
    editItem(item) {
      this.clienteAEditar = item.idCliente;
      this.dialogEdit = true;
      this.fetchPerfil(this.clienteAEditar);
    },

    //FUNCION PARA RECUPERAR LOS DATOS DE UN CLIENTE DESDE LA BASE DE DATOS
    async fetchPerfil(idCliente) {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/clientes/${idCliente}`);
        if (response.data && response.data.body && typeof response.data.body === 'object') {
          this.infoCliente = response.data.body;
          this.fechaDesdeServidor = this.infoCliente.fechadeNacimiento;
          this.formatearFecha(this.fechaDesdeServidor);
        } else {
          this.snackbarText = 'La respuesta del servidor no contiene datos válidos';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener datos del cliente';
        this.snackbar = true;
      }
    },

    //FUNCION PARA EDITAR CLIENTES
    fetchsaveItem() {
      if (!this.infoCliente.nombre || !this.infoCliente.apellido || !this.infoCliente.apellido2
        || !this.infoCliente.telefono || !this.infoCliente.fechadeNacimiento || !this.infoCliente.sexo) {
        this.alertaformulario = true;
        return;
      }
      axios.put(`https://${API_HOST}/api/vista/clientes/${this.clienteAEditar}`, {
        nombre: this.infoCliente.nombre,
        apellido: this.infoCliente.apellido,
        apellido2: this.infoCliente.apellido2,
        telefono: this.infoCliente.telefono,
        fechadeNacimiento: this.infoCliente.fechadeNacimiento,
        colonia: 'x',
        sexo: this.infoCliente.sexo,
        ine: this.infoCliente.ine,
        donde: this.infoCliente.donde,

        lastModifiedBy: sessionStorage.getItem('user'),
        lastModifiedDate: new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      })
        .then(() => {
          this.fetchClientes();
          this.dialogEdit = false;
          this.clienteAEditar = null;
          this.snackbarText = 'Cliente editado exitosamente';
          this.snackbar = true;
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al editar el cliente';
          this.snackbar = true;
        });
    },

    //CONFIRMACION DE ELIMINACION EN CASO DE DAR CLIC A UN CLIENTE POR ERROR
    confirmDeleteItem(item) {
      this.clienteAEliminar = item.idCliente;
      this.dialogDelete = true;
    },

    //FUNCION PARA ELIMINAR CLIENTES
    fetchDeleteItem() {
      axios.delete(`https://${API_HOST}/api/vista/clientes/${this.clienteAEliminar}`)
        .then(() => {
          this.fetchClientes();
          this.dialogDelete = false;
          this.clienteAEliminar = null;
          this.snackbarText = 'Cliente eliminado exitosamente';
          this.snackbar = true;
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al eliminar el cliente';
          this.snackbar = true;
        });
    },

    //FUNCION PARA AGREAGR A UN CLIENTE 
    async fetchAgregarCliente() {
      if (!this.nombre || !this.apellido || !this.apellido2 || !this.telefono
        || !this.fechadeNacimiento || !this.sexo) {
        this.alertaformulario = true;
        return;
      }
      try {
        await axios.post(`https://${API_HOST}/api/vista/clientes`, {
          idCliente: 0,
          nombre: this.nombre,
          apellido: this.apellido,
          apellido2: this.apellido2,
          telefono: this.telefono,
          fechadeNacimiento: this.fechadeNacimiento,
          colonia: 'x',
          sexo: this.sexo,
          ine: this.ine,
          donde: this.donde,

          createdBy: sessionStorage.getItem('user'),
          createdDate: new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        });

        this.fetchClientes();

        if (sessionStorage.getItem('user') === 'Recepcion') {
          this.fetchContar();
        }

        this.dialog = false,
          this.nombre = '',
          this.apellido = '',
          this.apellido2 = '',
          this.telefono = '',

          this.fechadeNacimiento = '',
          this.colonia = '',
          this.sexo = ''
        this.ine = ''
        this.donde = '',
          this.snackbarText = 'Usuario creado exitosamente';
        this.snackbar = true;
      } catch (error) {
        error
        this.snackbarText = 'Error al enviar los datos';
        this.snackbar = true;
      }
    },


    close() {
      this.dialog = false;
      this.nombre = '',
        this.apellido = '',
        this.apellido2 = '',
        this.telefono = '',

        this.fechadeNacimiento = '',
        this.colonia = '',
        this.sexo = ''
      this.ine = ''
      this.donde = ''
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    seleccionarOpcion(opcion) {
      this.opcionSeleccionada = opcion;
      if (opcion === 'Clientes') {
        this.selectedClientId = null;
        this.fetchContarVerificarMeta();
      }
    },
    saves() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    getLastModified(idCliente) {
      const cliente = this.clientes.find(c => c.idCliente === idCliente);
      return cliente ? cliente.lastModifiedBy || 'N/A' : 'N/A';
    },

    applyRoleBasedHeaders() {
      this.userRole = sessionStorage.getItem('role');
      this.filteredHeaders = this.headers;
    },

    //FETCH PARA PODER CONTAR LA CANTIDAD DE REGISTROS REALIZADOS POR USUARIO RECEPCION
    async fetchContar() {
      try {
        await axios.put(`https://${API_HOST}/api/vista/contador/actualizarTotal`);
      } catch (error) {
        //this.snackbarText = 'Error al contar clientes';
        //this.snackbar = true;
      }
    },

    async fetchContarVerificarMeta() {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/contador`);
        // Accede al primer elemento del arreglo body y luego a la propiedad total
        this.totalContador = response.data.body[0].total;
        // Verifica si el contador es mayor o igual a 50
        this.showNotificationButton = this.totalContador >= 50;
      } catch (error) {
        //this.snackbarText = 'Error al verificar total de contador';
        //this.snackbar = true;
        return null;
      }
    },

    async fetchResetearContador() {
      try {
        await axios.put(`https://${API_HOST}/api/vista/contador/resetearTotal`);
      } catch (error) {
        //this.snackbarText = 'Error al resetar total de contador';
        //this.snackbar = true;
      }
    },

    openDialogNoti() {
      this.dialogNoti = true;
      this.fetchContarVerificarMeta();
    },
    closeDialogNoti() {
      this.dialogNoti = false;
      this.fetchResetearContador();
      this.showNotificationButton = false;
    },

  },

  created() {
    this.fetchClientes();
  },

  computed: {
    items0() {
      return [
        {
          name: "Users",
          children: this.users,
        },
      ];
    },
    selected() {
      if (!this.active.length) return undefined;
      const id = this.active[0];
      return this.users.find((user) => user.id === id);
    },
    headers() {
      return [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Primer Apellido', value: 'apellido' },
        { text: 'Segundo Apellido', value: 'apellido2' },
        { text: "Acciones", value: "actions", sortable: false },
        { text: 'Detalles', value: 'details', sortable: false },
      ];
    },
  }
};

</script>

<style>
.active-button {
  background-color: rgba(0, 0, 0, 0.097);
}

.active {
  background-color: rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.oculto {
  opacity: 0;
  pointer-events: none;
}

/* Opcional: estilo para el tooltip */
[v-tooltip] {
  position: relative;
  cursor: pointer;
}

[v-tooltip]::after {
  content: attr(v-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  display: none;
}

[v-tooltip]:hover::after {
  display: block;
}

.notification-icon {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}


.notification-icon .v-icon {
  font-size: 24px;
  /* Ajusta según tus necesidades */
}

.notification-icon .v-badge {
  position: absolute;
  top: -4px;
  right: -4px;
}
</style>
