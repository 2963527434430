import axios from "axios";
import { API_HOST } from "./utils/constants.js";

const instance = axios.create({
  baseURL: `https://${API_HOST}/api/vista/clientes`,
  timeout: 10000,
});

// Interceptor para manejar errores globalmente
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Verificar si estamos en producción o en desarrollo
    if (process) {
      // En producción, muestra un mensaje genérico
      console.error("Ocurrió un error. Por favor, intenta de nuevo más tarde.");
    } else {
      // En desarrollo, muestra el error completo para depuración
      console.error("Error en la solicitud.");
    }

    // Puedes mostrar un mensaje genérico al usuario aquí si lo deseas
    return Promise.reject(error);
  }
);

export default instance;
