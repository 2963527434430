<template>
  <div>

    <v-row class="justify-center ma-auto" cols="12" sm="12">
      <v-col cols="12" md="12">
        <v-dialog ref="dialogDate" v-model="modal" :return-value.sync="date" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="date" label="Fecha" outlined color="#35CDCD" prepend-icon="mdi-calendar" readonly
              v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker id="date" v-model="date" color="#35CDCD" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="red" @click="modal = false">Cancelar</v-btn>
            <v-btn text color="#35CDCD" @click="$refs.dialogDate.save(date)">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col cols="12" md="12">
        <v-dialog ref="dialogTime" v-model="modal2" :return-value.sync="time" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="time" label="Hora" outlined color="#35CDCD"
              prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker id="time" v-if="modal2" color="#35CDCD" v-model="time" full-width>
            <v-spacer></v-spacer>
            <v-btn text color="red" @click="modal2 = false">Cancelar</v-btn>
            <v-btn text color="#35CDCD" @click="$refs.dialogTime.save(time)">OK</v-btn>
          </v-time-picker>
        </v-dialog>
      </v-col>

      <v-col>
        <v-row class="justify-center ma-auto">
          <v-checkbox color="#35CDCD" id="usapluma" v-model="isChecked" :label="`Utiliza NUT`"></v-checkbox>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-start">
            <v-btn @click="abrirModal3" color="#35CDCD" block dark>Ver agenda</v-btn>
          </v-col>
        </v-row>

        <v-card-actions class="justify-center pa-5">
          <v-btn color="#35CDCD" text @click="handleSubmit">Guardar</v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <v-dialog v-model="alertaformulario" max-width="400">
      <v-card>
        <v-card-text class="text-center pa-4">
          <span class="headline">¡Alerta!</span>
          <div style="margin-top: 30px;"> <!-- Espacio adicional entre el span y el párrafo -->
            <p>Por favor, complete todos los campos obligatorios.</p>
          </div>
          <v-card-actions class="justify-center">
            <v-btn color="#35CDCD" text @click="alertaformulario = false">Entendido</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalVisible3" max-width="1200">
      <v-card>
        <v-btn icon class="close-button" @click="modalVisible3 = false">
          <v-icon color="red">mdi-close-box</v-icon>
        </v-btn>
        <v-card-text class="text-center">
          <Agenda />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from '../axios';
import { API_HOST } from '../utils/constants.js';
import Agenda from '@/views/AgendaView.vue';

export default {
  props: {
    idCita: {
      type: Number,
      required: true
    },
    tipoServicio: {
      type: String,
    },
  },
  data() {
    return {
      isChecked: false,
      modal: false,
      modal2: false,
      time: null,
      date: null,
      usapluma: null,
      modalVisible3: false,
      alertaformulario: false,

      snackbarText: '',
      snackbar: false,
      timeout: 3000, // Duración del snackbar en milisegundos

      servicio: '',
      infoServicio: '',
    }
  },
  components: {
    Agenda,
  },
  methods: {
    async handleSubmit() {
      if (!this.date || !this.time) {
        this.alertaformulario = true;
        return;
      }
      await this.guardarSesion(this.idCita);
      this.$emit('guardar-sesion');
      this.$emit('guardar-snakbar');
    },

    async guardarSesion(idCita) {
      // Verifica que la fecha y la hora estén definidas
      if (!this.date || !this.time) {
        this.alertaformulario = true;
        this.snackbarText = 'Fecha y hora son requeridos';
        this.snackbar = true;
        return;
      }
      // Verifica si el checkbox también está definido si es necesario
      if (this.isChecked === true) {
        this.usapluma = `1`; // Asegúrate de que isChecked tenga un valor predeterminado
      } else if (this.isChecked === false) {
        this.usapluma = `0`; // Asegúrate de que isChecked tenga un valor predeterminado
      }

      try {
        // Envía los datos al servidor
        await axios.post(`https://${API_HOST}/api/vista/sesiones/`, {
          fecha: this.date,
          idCita: idCita,
          hora: this.time,
          cantidadglp: this.usapluma,
          createdBy: sessionStorage.getItem('user'),
          createdDate: new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
          tipoServicio: this.tipoServicio

        });
        // Muestra un mensaje de éxito
        this.snackbarText = 'Sesión registrada exitosamente';
        this.snackbar = true;
        // Limpia los campos después de guardar
        this.date = '';
        this.time = '';
        this.isChecked = false; // Resetea el checkbox
        this.modal = false;
        this.modal2 = false;

      } catch (error) {
        // Manejo de errores
        error
        this.snackbarText = 'Error al enviar los datos';
        this.snackbar = true;
      }
    },

    abrirModal3() {
      this.modalVisible3 = true;
    },

    cerrar() {
      this.modalVisible3 = false;
    },

  }
}
</script>
