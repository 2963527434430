<template>
  <v-app>
    <v-row class="mx-0 mt-0">

      <!-- TABLA DE Gastos -->
      <v-col cols="12" md="12">
        <v-card class="elevation-10">
          <v-card-title>
            <h2>Gastos</h2>
            <v-spacer></v-spacer>
            <!-- Parte de búsqueda de gastos -->
            <v-text-field v-model="search" append-icon="mdi-magnify" color="#35CDCD" outlined label="Buscar" single-line
              hide-details>
            </v-text-field>

            <v-row justify="center">
              <form @submit.prevent="guardarGasto">
                <div class="d-flex justify-center mb-0 mt-0">
                  <v-dialog v-model="dialog" persistent max-width="800px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="#35CDCD" dark v-bind="attrs" v-on="on">
                        Agregar Gasto
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Agregar Gastos</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <div>
                            <form @submit.prevent="guardarGasto">
                              <v-row>
                                <v-col cols="12" md="12">
                                  <v-text-field label="Descripción" type="text" color="#35CDCD" outlined
                                    v-model="descripcion" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field label="Monto (MXN)" type="number" pattern="[0-9]+([.,][0-9]+)?" min="1"
                                    max="20000000" color="#35CDCD" outlined v-model="mxn" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-dialog ref="dialog1" v-model="modal" color="#35CDCD" :return-value.sync="Fecha"
                                    persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field id="Fecha" color="#35CDCD" outlined v-model="Fecha" label="Fecha"
                                        required prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                        v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker id="Fecha" color="#35CDCD" outlined v-model="Fecha" scrollable>
                                      <v-spacer></v-spacer>
                                      <v-btn text color="red" @click="modal = false">Cancelar</v-btn>
                                      <v-btn text color="#35CDCD" @click="$refs.dialog1.save(Fecha)">OK</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12">
                                  <v-textarea label="Observaciones" type="text" color="#35CDCD" outlined
                                    v-model="observaciones"></v-textarea>
                                </v-col>
                              </v-row>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click="dialog = false">
                                  Cancelar
                                </v-btn>
                                <v-btn color="#35CDCD" type="submit" text> Guardar
                                </v-btn>
                              </v-card-actions>
                            </form>
                          </div>
                        </v-container>
                      </v-card-text>

                    </v-card>
                  </v-dialog>
                </div>
              </form>
            </v-row>

            <!-- Aquí termina búsqueda de gastos -->
          </v-card-title>
          <!-- Tabla de CRUD para gastos -->
          <v-data-table :headers="headers" :items="formatoGasto()" :search="search" item-key="idGasto">
            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-icon size="25px" color="#35CDCD" class="mx-1" @click.stop="editItem(item)">
                mdi-pencil
              </v-icon> -->
              <v-icon size="25px" color="red" class="mx-1" @click.stop="confirmDeleteItemG(item)" item-key="idGasto">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <v-dialog v-model="dialogDelete" max-width="390">
            <v-card>
              <v-card-title class="headline">Eliminar gasto </v-card-title>
              <v-card-text>¿Está seguro que desea eliminar este gasto?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="dialogDelete = false">Cancelar</v-btn>
                <v-btn color="#35CDCD" text @click="fetchDeleteItemG">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Fin del CRUD -->

        </v-card>
      </v-col>
      <!-- Dialogo para mostrar alerta -->
      <v-dialog v-model="alertaformulario" max-width="400">
        <v-card>
          <v-card-text class="text-center pa-4">
            <span class="headline">¡Alerta!</span>
            <div style="margin-top: 30px;"> <!-- Espacio adicional entre el span y el párrafo -->
              <p>Por favor, complete todos los campos obligatorios.</p>
            </div>
            <v-card-actions class="justify-center">
              <v-btn color="#35CDCD" text @click="alertaformulario = false">Entendido</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
</template>

<script>

import axios from '../axios';
import { API_HOST } from '../utils/constants.js';

export default {
  name: 'GastosView',
  data() {
    return {
      dialog: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      dialogDelete: false,
      search: "",
      descripcion: '',
      mxn: '',
      observaciones: '',
      Fecha: '',
      dateFormat: 'YYYY-MM-DD',
      alertaformulario: false,
      gastos: [],
      fechaDesdeServidor: [],
      fechaFormateada: [],
      gastoAEliminar: null,
      snackbar: false,
      snackbarText: '',
      timeout: 3000, // Duración en milisegundos (opcional)

    };
  },

  methods: {
    formatearFecha(fechaDesdeServidor) {
      try {
        const fechasFormateadas = fechaDesdeServidor.map(fecha => fecha.split('T')[0]);
        this.fechaFormateada = fechasFormateadas;
      } catch (error) {
        error
        this.snackbarText = 'Error al formatear la fecha';
        this.snackbar = true;
      }
    },

    fetchGastos() {
      axios.get(`https://${API_HOST}/api/vista/gastos`)
        .then(response => {
          let data = response.data.body;
          if (Array.isArray(data)) {
            this.gastos = data;
            this.fechaDesdeServidor = this.gastos.map(gasto => gasto.fecha);
            this.formatearFecha(this.fechaDesdeServidor);
          } else {
            this.snackbarText = 'La respuesta de la API no es válida';
            this.snackbar = true;
          }
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al obtener los gastos';
          this.snackbar = true;
        });
    },

    async guardarGasto() {
      if (!this.descripcion || !this.mxn || !this.Fecha) {
        this.alertaformulario = true;
        return;
      }
      try {
        await axios.post(`https://${API_HOST}/api/vista/gastos`, {
          descripcion: this.descripcion,
          mxn: this.mxn,
          observaciones: this.observaciones,
          Fecha: this.Fecha,
        });
        this.dialog = false;
        // Limpia el formulario después de enviarlo
        this.fetchGastos();
        this.descripcion = '';
        this.mxn = '';
        this.observaciones = '';
        this.Fecha = '';
        this.snackbarText = 'Creado exitosamente';
        this.snackbar = true;
      } catch (error) {
        error
        this.snackbarText = 'Error al enviar los datos';
        this.snackbar = true;
      }
    },

    //CONFIRMACION DE ELIMINACION EN CASO DE DAR CLIC A UN CLIENTE POR ERROR
    confirmDeleteItemG(item) {
      this.gastoAEliminar = item.idGasto; // Asigna el ID correcto del registro que se va a eliminar
      this.dialogDelete = true; // Muestra el diálogo de confirmación de eliminación
    },

    //FUNCION PARA ELIMINAR CLIENTES
    fetchDeleteItemG() {
      // Verifica que this.ventaAEliminar tenga un valor válido antes de hacer la solicitud DELETE
      if (this.gastoAEliminar !== null && this.gastoAEliminar !== undefined) {
        axios.delete(`https://${API_HOST}/api/vista/gastos/${this.gastoAEliminar}`)
          .then(() => {
            this.fetchGastos(); // Actualiza la lista de ventas después de eliminar
            this.dialogDelete = false; // Cierra el diálogo de confirmación de eliminación
            this.gastoAEliminar = null; // Reinicia la variable
            this.snackbarText = 'Gasto eliminado exitosamente';
            this.snackbar = true;
          })
          .catch(error => {
            error
            this.snackbarText = 'Error al eliminar el gasto';
            this.snackbar = true;
          });
      } else {
        this.snackbarText = 'ID a eliminar no válido';
        this.snackbar = true;
      }
    },

    formatoGasto() {
      return this.gastos.map(gasto => ({
        ...gasto,
        fecha: gasto.fecha.substring(0, 10)
      }));
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

  },
  created() {
    this.fetchGastos();
  },


  computed: {
    headers() {
      return [
        { text: 'Descripcion', value: 'descripcion' },
        { text: 'MXN', value: 'mxn' },
        { text: 'Observaciones', value: 'observaciones' },
        { text: 'Fecha', value: 'fecha' },
        { text: "Acciones", value: "actions", sortable: false },
      ];
    },
  },
};
</script>

<style>
/* Estilos específicos de ClientesView */
</style>