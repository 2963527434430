<template>
  <v-app>
    <v-row class="mx-0 mt-0">
      <!-- Primera tarjeta -->
      <v-col cols="12" md="12">
        <v-card class="elevation-10">
          <v-card-title>
            <h2>Comisiones</h2>
            <v-spacer></v-spacer>
            <!-- Parte de búsqueda de ventas -->
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" color="#35CDCD" outlined
              hide-details>
            </v-text-field>
            <!-- Aquí termina búsqueda de ventas -->
          </v-card-title>
          <!-- Tabla de CRUD para ventas -->
          <v-data-table :headers="headers" :items="formatoComision()" :search="search">

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>
  </v-app>

</template>

<script>

import axios from '../axios';
import { API_HOST } from '../utils/constants.js';

export default {
  name: "ComisionesView",
  data: () => ({
    comisiones: [],
    fechaDesdeServidor: [],
    fechaFormateada: [],
    search: '',

    snackbar: false,
    snackbarText: '',
    timeout: 3000,

  }),

  methods: {
    formatearFecha(fechaDesdeServidor) {
      try {
        const fechasFormateadas = fechaDesdeServidor.map(fecha => fecha.split('T')[0]);
        this.fechaFormateada = fechasFormateadas;
      } catch (error) {
        error
        this.snackbarText = 'Error al formatear la fecha';
        this.snackbar = true;
      }
    },

    fetchComisiones() {
      axios.get(`https://${API_HOST}/api/vista/comisiones`)
        .then(response => {
          const data = response.data.body;
          if (Array.isArray(data)) {
            this.comisiones = data;
            this.fechaDesdeServidor = this.comisiones.map(comision => comision.fecha);
            this.formatearFecha(this.fechaDesdeServidor);
          } else {
            this.snackbarText = 'La respuesta de la API no es válida';
            this.snackbar = true;
          }
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al obtener los ventas';
          this.snackbar = true;
        });
    },


    formatoComision() {
      return this.comisiones.map(comision => ({
        ...comision,
        fecha: comision.fecha.substring(0, 10)  // Aquí asumimos que la fecha está en formato 'YYYY-MM-DD HH:mm:ss'
      }));
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },

  created() {
    this.fetchComisiones();
  },


  computed: {
    headers() {
      return [
        { text: 'Empleado', value: 'puesto' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Monto ', value: 'montocomision' },

      ];
    },
  },

  filters: {
    formatDate(value) {
      if (!value) return '';
      const date = new Date(value);
      const day = date.getDate();
      const month = date.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por eso sumamos 1
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  },



};
</script>

<style scoped>
/* Estilos específicos para la tabla */
</style>