<template>
  <v-app>

    <v-container fluid>

      <v-row class="justify-center">
        <!-- Botón para abrir el diálogo -->
        <v-btn color="#35CDCD" dark @click="estadistics" dense>Ver Estadísticas de clientes</v-btn>

        <!-- Diálogo con el gráfico -->
        <v-dialog v-model="estadisticas" max-width="500" persistent>
          <v-sheet class="mx-auto" color="white" elevation="12" max-width="100%" height="500px"
            style="padding: 0; position: relative;">
            <!-- Contenedor para el gráfico -->
            <div class="chart-container" style="height: calc(100% - 48px); overflow: auto;">
              <svg width="100%" height="100%">
                <!-- Eje Y (valores de 5 en 5) con "Cantidad de clientes" -->
                <g v-for="n in 9" :key="'y-axis-' + n">
                  <text :x="5" :y="400 - n * 40" fill="black" font-size="12">{{ n * 5 }}</text>
                  <line :x1="60" :y1="400 - n * 40" :x2="500" :y2="400 - n * 40" stroke="#ccc" />
                </g>

                <!-- Barras con colores e íconos -->
                <g v-for="(item, index) in values" :key="'bar-' + index">
                  <rect :x="80 + index * 100" :y="400 - item * 8" :width="60" :height="item * 8"
                    :fill="barColors[index]" stroke="#333" stroke-width="1" />

                  <!-- Ícono debajo de la barra con tooltip -->
                  <foreignObject :x="80 + index * 100" :y="420" :width="60" :height="40">
                    <div class="d-flex flex-column align-center" style="height: 100%;">
                      <v-tooltip bottom :value="tooltips[index]">
                        <template v-slot:activator="{ on, attrs }">
                          <span :class="icons[index]" style="font-size: 24px; color: black;" v-bind="attrs"
                            v-on="on"></span>
                        </template>
                      </v-tooltip>
                      <div style="font-size: 12px; color: black; text-align: center;">
                        {{ labels[index] }}
                      </div>
                    </div>
                  </foreignObject>
                </g>
              </svg>
            </div>
            <!-- Contenedor para el botón -->
            <v-btn color="#35CDCD" dark @click="estadisticas = false"
              style="position: absolute; bottom: 0; left: 0; right: 0; margin: 0;">
              Entendido
            </v-btn>
          </v-sheet>
        </v-dialog>
      </v-row>

      <div class="linea-horizontal pa-5"></div>
      <v-row>

        <v-col cols="12" md="4" class="mx-auto">
          <!-- Tarjeta 1: Agregar Nuevo Servicio -->
          <v-card class="elevation-10 ma-auto pa-8">
            <v-card-title>
              <span class="text-h5 ma-auto">Agregar Nuevo Servicio</span>
            </v-card-title>
            <v-card-text class="ma-auto">
              Al agregar un nuevo servicio, es importante recordar que los productos se categorizan según la primera
              palabra de su nombre. Si se agregan más sesiones para un servicio existente, la primera palabra del nombre
              debe permanecer igual; lo único que cambiará será la duración y el costo del servicio.
            </v-card-text>
            <v-container dense>
              <form @submit.prevent="guardarServicio">
                <v-row>
                  <v-col cols="11" md="11" class="ma-auto">
                    <v-text-field type="text" color="#35CDCD" label="Nombre" placeholder="" outlined id="nombre"
                      v-model="nombre" required></v-text-field>
                    <v-text-field type="number" color="#35CDCD" label="Cantidad de Sesiones" outlined id="duracion"
                      min="1" max="20000000" v-model="duracion" required></v-text-field>
                    <v-text-field type="number" color="#35CDCD" label="Costo" outlined id="costo" min="1" max="20000000"
                      v-model="costo" required></v-text-field>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="#35CDCD" dark block large @click="guardarServicio">Guardar</v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>

              </form>

            </v-container>
            <v-dialog v-model="alertaformulario" max-width="400">
              <v-card>
                <v-card-text class="text-center pa-4">
                  <span class="headline">¡Alerta!</span>
                  <div style="margin-top: 30px;">
                    <p>Por favor, complete todos los campos obligatorios.</p>
                  </div>
                  <v-card-actions class="justify-center">
                    <v-btn color="#35CDCD" text @click="alertaformulario = false">Entendido</v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>



          </v-card>
        </v-col>
        <v-col cols="12" md="8">
          <v-card class="elevation-10">
            <v-card-title>
              <h2>Servicios</h2>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" color="#35CDCD" outlined
                hide-details>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="headers" :search="search" :items="servicios">
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon size="25px" color="#35CDCD" class="mx-1" @click.stop="editItem(item)" item-key="idServicio">
                  mdi-pencil
                </v-icon>
                <v-icon size="25px" color="red" class="mx-1" @click.stop="confirmDeleteItemS(item)"
                  item-key="idServicio">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
            <!-- Fin del CRUD -->





            <v-dialog v-model="dialogEditS" max-width="500">
              <v-card>
                <v-card-title color="#35CDCD">
                  <span class="text-h5" color="#35CDCD">Editar Servicio</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field type="text" color="#35CDCD" label="Nombre" outlined id="nombre"
                          v-model="infoServicio.nombre">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field type="number" color="#35CDCD" label="Duracion" outlined id="duracion" min="1"
                          max="20000000" v-model="infoServicio.duracion"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field type="number" color="#35CDCD" label="Costo" outlined id="costo" min="1"
                          max="20000000" v-model="infoServicio.costo"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>


                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="dialogEditS = false">
                      Cerrar
                    </v-btn>
                    <v-btn type="submit" color="#35CDCD" text @click="fetchsaveItem1">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card-text>

                <v-spacer></v-spacer>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
      <div class="linea-horizontal pa-5"></div>
      <v-row>


        <v-col cols="12" md="4" class="mx-auto">
          <!-- Tarjeta 2: Agregar Nuevo Empleado -->
          <v-card class="elevation-10 ma-auto pa-8" max-width="500">
            <v-card-title>
              <span class="text-h5 ma-auto">Agregar Nuevo Empleado</span>
            </v-card-title>
            <v-card-text class="ma-auto">
              Ingrese un empleado que atenderá citas
            </v-card-text>
            <v-container dense>
              <form @submit.prevent="guardarEmpleado">
                <v-row>
                  <v-col cols="11" md="11" class="ma-auto">
                    <v-text-field type="text" color="#35CDCD" label="Nombre" outlined id="nombreEmpleado"
                      v-model="nombreEmpleado" required></v-text-field>
                    <v-text-field type="text" color="#35CDCD" label="Puesto" outlined id="puesto" v-model="puesto"
                      required></v-text-field>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="#35CDCD" dark block large @click="guardarEmpleado">Guardar</v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>

              </form>
            </v-container>
            <v-dialog v-model="alertaformulario" max-width="400">
              <v-card>
                <v-card-text class="text-center pa-4">
                  <span class="headline">¡Alerta!</span>
                  <div style="margin-top: 30px;">
                    <p>Por favor, complete todos los campos obligatorios.</p>
                  </div>
                  <v-card-actions class="justify-center">
                    <v-btn color="#35CDCD" text @click="alertaformulario = false">Entendido</v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>

        <v-col cols="12" md="8">
          <v-card class="elevation-10">
            <v-card-title>
              <h2>Empleados</h2>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" color="#35CDCD" outlined
                hide-details>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="headers2" :search="search" :items="empleados">
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon size="25px" color="#35CDCD" class="mx-1" @click.stop="editItem2(item)" item-key="idEmpleado">
                  mdi-pencil
                </v-icon>
                <v-icon size="25px" color="red" class="mx-1" @click.stop="confirmDeleteItemE(item)"
                  item-key="idEmpleado">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
            <!-- Fin del CRUD -->

            <v-dialog v-model="dialogDelete1" max-width="390">
              <v-card>
                <v-card-title class="headline">Eliminar empleado </v-card-title>
                <v-card-text>¿Está seguro que desea eliminar este Empleado?</v-card-text>

                <v-card-text>Se eliminaran las citas que ha realizado el empleado</v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="dialogDelete1 = false">Cancelar</v-btn>
                  <v-btn color="#35CDCD" text @click="fetchDeleteItemE">Confirmar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogEditE" max-width="500">
      <v-card>
        <v-card-title color="#35CDCD">
          <span class="text-h5" color="#35CDCD">Editar Empleado</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field type="text" color="#35CDCD" label="Nombre" outlined id="nombreEmpleado"
                  v-model="infoEmpleado.nombre">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field type="text" color="#35CDCD" label="Puesto" outlined id="puesto"
                  v-model="infoEmpleado.puesto"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="dialogEditE = false">
              Cerrar
            </v-btn>
            <v-btn type="submit" color="#35CDCD" text @click="fetchsaveItem2">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card-text>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>

    <!-- Dialogo para mostrar alerta -->
    <v-dialog v-model="alertaformulario" max-width="400">
      <v-card>
        <v-card-text class="text-center pa-4">
          <span class="headline">¡Alerta!</span>
          <div style="margin-top: 30px;"> <!-- Espacio adicional entre el span y el párrafo -->
            <p>Por favor, complete todos los campos obligatorios.</p>
          </div>
          <v-card-actions class="justify-center">
            <v-btn color="#35CDCD" text @click="alertaformulario = false">Entendido</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="390">
      <v-card>
        <v-card-title class="headline">Eliminar servicio </v-card-title>
        <v-card-text>¿Está seguro que desea eliminar este Servicio?</v-card-text>
        <v-card-text>Se eliminaran las citas que incluyen este servicio</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialogDelete = false">Cancelar</v-btn>
          <v-btn color="#35CDCD" text @click="fetchDeleteItemS">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>

  </v-app>
</template>


<script>

//iNYECCION 30 MINUTOS, OTROS TRATATMIENTOS 1 HORA


//import { Bar } from 'vue-chartjs'
import axios from '../axios';
import { API_HOST } from '../utils/constants.js';

export default {
  name: 'AgregarView',
  // name1: 'BarChart',
  //components: { Bar },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      dialogDelete1: false,
      dialog1: false,
      dialog2: false,
      estadisticas: false,
      opcionSeleccionada: null,
      alertaformulario: false,
      opcionSubMenu: null,

      nombre: '',
      duracion: '',
      costo: '',

      nombreEmpleado: '',
      puesto: '',

      menu: false,
      modal: false,
      menu2: false,

      idEmpleado: '',
      idServicio: '',
      empleados: [],
      servicios: [],
      infoServicio: {
        nombre: '',
        duracion: '',
        costo: '',
      },
      infoEmpleado: {
        nombre: '',
        puesto: '',
      },

      servicioAEliminar: null,
      empleadoAEliminar: null,

      search: '',
      dialogEditS: false,
      dialogEditE: false,
      snackbar: false,
      snackbarText: '',
      timeout: 3000,
      idProducto: '',

      values: [10, 20, 15, 25], // Ejemplo de valores
      labels: ['Facebook', 'Instagram', 'Recomendado por empleado', 'Recomendado por cliente'],
      barColors: ['#000000', '#000000', '#000000', '#000000'], // Colores de Facebook, Instagram, recomendado por empleado y cliente
      icons: [
        'mdi mdi-facebook',
        'mdi mdi-instagram',
        'mdi mdi-clipboard-account-outline',
        'mdi mdi-account-injury-outline'
      ],
      tooltips: ['Facebook', 'Instagram', 'Recomendado por empleado', 'Recomendado por cliente'] // Textos para tooltips
    };
  },

  methods: {

    async guardarServicio() {
      if (!this.nombre || !this.duracion || !this.costo) {
        this.alertaformulario = true;
        return;
      }
      try {
        await axios.post(`https://${API_HOST}/api/vista/servicios`, {
          nombre: this.nombre,
          duracion: this.duracion,
          costo: this.costo,
        });
        this.fetchServicios();
        this.nombre = '';
        this.duracion = '';
        this.costo = '';
        this.snackbarText = 'Se ha agregado el nuevo servicio';
        this.snackbar = true;
      } catch (error) {
        error
        this.snackbarText = 'Error al enviar los datos';
        this.snackbar = true;
      }
    },

    //FUNCION PARA VER LOS servicios EN LA TABLA
    fetchServicios() {
      axios.get(`https://${API_HOST}/api/vista/servicios`)
        .then(response => {
          let data = response.data.body;
          if (Array.isArray(data)) {
            this.servicios = data;
          } else {
            this.snackbarText = 'La respuesta de la API no es válida';
            this.snackbar = true;
          }
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al obtener los servicios';
          this.snackbar = true;
        });
    },

    async guardarEmpleado() {
      if (!this.nombreEmpleado || !this.puesto) {
        this.alertaformulario = true;
        return;
      }
      try {
        await axios.post(`https://${API_HOST}/api/vista/empleados`, {
          nombre: this.nombreEmpleado,
          puesto: this.puesto,

        });
        this.fetchEmpleados();
        this.nombreEmpleado = '';
        this.puesto = '';

        this.snackbarText = 'Se ha agregado el nuevo empleado';
        this.snackbar = true;
      } catch (error) {
        error
        this.snackbarText = 'Error al enviar los datos';
        this.snackbar = true;
      }
    },

    //FUNCION PARA VER LOS empleados EN LA TABLA
    fetchEmpleados() {
      axios.get(`https://${API_HOST}/api/vista/empleados`)
        .then(response => {
          let data = response.data.body;
          if (Array.isArray(data)) {
            this.empleados = data;
          } else {
            this.snackbarText = 'La respuesta de la API no es válida';
            this.snackbar = true;
          }
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al obtener los empleados';
          this.snackbar = true;
        });
    },

    ////////////////////////////////////////////////////EDITAR SERVICIOS//////////////////////////////////////////////////

    editItem(item) {
      this.servicioAEditar = item.idServicio;
      this.dialogEditS = true;
      this.fetchServicio(this.servicioAEditar);
    },

    async fetchServicio(idServicio) {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/servicios/${idServicio}`);
        if (response.data && response.data.body && typeof response.data.body === 'object') {
          this.infoServicio = response.data.body;
        } else {
          this.snackbarText = 'La respuesta del servidor no contiene datos válidos';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener datos del producto';
        this.snackbar = true;
      }
    },

    //FUNCION PARA EDITAR Servicios
    fetchsaveItem1() {
      if (!this.infoServicio.nombre || !this.infoServicio.duracion || !this.infoServicio.costo) {
        this.alertaformulario = true;
        return;
      }

      axios.put(`https://${API_HOST}/api/vista/servicios/${this.servicioAEditar}`, {
        nombre: this.infoServicio.nombre,
        duracion: this.infoServicio.duracion,
        costo: this.infoServicio.costo,

        //  lastModifiedBy: sessionStorage.getItem('user'),
        //  lastModifiedDate: new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      })
        .then(() => {
          this.fetchServicios();
          this.dialogEditS = false;
          this.servicioAEditar = null;
          this.snackbarText = 'Servicio actualizado';
          this.snackbar = true;
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al editar el Servicio';
          this.snackbar = true;
        });
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////EDITAR EMPLEADOS//////////////////////////////////////////////////

    editItem2(item) {
      this.empleadoAEditar = item.idEmpleado;
      this.dialogEditE = true;
      this.fetchEmpleado(this.empleadoAEditar);
    },

    async fetchEmpleado(idEmpleado) {
      const url = `https://${API_HOST}/api/vista/empleados/${idEmpleado}`;
      try {
        const response = await axios.get(url);
        if (response.data && response.data.body && typeof response.data.body === 'object') {
          this.infoEmpleado = response.data.body;
        } else {
          this.snackbarText = 'La respuesta del servidor no contiene datos válidos';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener datos del empleado';
        this.snackbar = true;
        if (error.response) {
          this.snackbarText = 'Detalles del error de respuesta';
          this.snackbar = true;
        } else {
          this.snackbarText = 'Error sin respuesta del servidor';
          this.snackbar = true;
        }
      }
    },



    //FUNCION PARA EDITAR Servicios
    fetchsaveItem2() {
      if (!this.infoEmpleado.nombre || !this.infoEmpleado.puesto) {
        this.alertaformulario = true;
        return;
      }

      axios.put(`https://${API_HOST}/api/vista/empleados/${this.empleadoAEditar}`, {
        nombre: this.infoEmpleado.nombre,
        puesto: this.infoEmpleado.puesto,

      })
        .then(() => {
          this.fetchEmpleados();
          this.dialogEditE = false;
          this.empleadoAEditar = null;
          this.snackbarText = 'Empleado actualizado';
          this.snackbar = true;
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al editar el empleado';
          this.snackbar = true;
        });
    },

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////ELIMINAR SERVICIOS//////////////////////////////////////////

    //CONFIRMACION DE ELIMINACION EN CASO DE DAR CLIC A UN CLIENTE POR ERROR
    confirmDeleteItemS(item) {

      //  console.log('ID a eliminar:', item.idServicio);
      this.servicioAEliminar = item.idServicio;
      this.dialogDelete = true;
    },

    fetchDeleteItemS() {

      if (this.servicioAEliminar !== null && this.servicioAEliminar !== undefined) {
        axios.delete(`https://${API_HOST}/api/vista/servicios/${this.servicioAEliminar}`)
          .then(() => {
            this.fetchServicios(); // Actualiza la lista de ventas después de eliminar
            this.dialogDelete = false; // Cierra el diálogo de confirmación de eliminación
            this.servicioAEliminar = null; // Reinicia la variable
            this.snackbarText = 'Se ha eliminado el servicio';
            this.snackbar = true;
          })
          .catch(error => {
            error
            this.snackbarText = 'Error al eliminar el servicio';
            this.snackbar = true;
          });
      } else {
        this.snackbarText = 'ID a eliminar no válido';
        this.snackbar = true;
      }
    },


    //////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////ELIMINAR EMPLEADOS//////////////////////////////////////////

    //CONFIRMACION DE ELIMINACION EN CASO DE DAR CLIC A UN CLIENTE POR ERROR
    confirmDeleteItemE(item) {
      this.empleadoAEliminar = item.idEmpleado;
      this.dialogDelete1 = true;
    },

    fetchDeleteItemE() {

      if (this.empleadoAEliminar !== null && this.empleadoAEliminar !== undefined) {
        axios.delete(`https://${API_HOST}/api/vista/empleados/${this.empleadoAEliminar}`)
          .then(() => {
            this.fetchEmpleados(); // Actualiza la lista de ventas después de eliminar
            this.dialogDelete1 = false; // Cierra el diálogo de confirmación de eliminación
            this.empleadoAEliminar = null; // Reinicia la variable
            this.snackbarText = 'Se ha eliminado el empleado';
            this.snackbar = true;
          })
          .catch(error => {
            error
            this.snackbarText = 'Error al eliminar el empleado';
            this.snackbar = true;
          });
      } else {
        this.snackbarText = 'ID a eliminar no válido';
        this.snackbar = true;
      }
    },

    //FUNCION PARA LAS ESTADISTICAS DE LOS CLIENTES
    fetchEstadisticas() {
      axios.get(`https://${API_HOST}/api/vista/clientes/estadisticas/donde`)
        .then(response => {
          let data = response.data.body;
          if (Array.isArray(data)) {
            // Transformar los datos para labels y values
            this.labels = data.map(item => item.nombre);
            this.values = data.map(item => item.total);
          } else {
            this.snackbarText = 'La respuesta de la API no es válida';
            this.snackbar = true;
          }
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al obtener los clientes';
          this.snackbar = true;
        });
    },

    //FUNCION PARA ABRIR MODAL DE ESTADISTICAS

    estadistics() {
      this.estadisticas = true;
    }


  },


  computed: {

    headers() {
      return [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Costo', value: 'costo' },
        { text: 'Cantidad de Sesiones', value: 'duracion' },
        { text: "Acciones", value: "actions", sortable: false },
      ];
    },
    headers2() {
      return [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Puesto', value: 'puesto' },
        { text: "Acciones", value: "actions", sortable: false },
      ];
    },
  },

  created() {
    this.fetchServicios();
    this.fetchEmpleados();
    this.fetchEstadisticas();

  },
};
</script>

<style>
.linea-horizontal {
  border-top: 1px solid #ccc;
  margin: 20px 0;
  /* Ajusta el margen según necesites */
}


.v-sheet--offset {
  top: -24px;
  position: relative;
}

.v-sparkline {
  font-size: 12px;
  /* Cambia este valor para ajustar el tamaño del texto */
}

.v-sparkline .v-sparkline__label {
  font-size: 12px;
  /* Ajusta el tamaño del texto de las etiquetas si es necesario */
}

.v-sparkline {
  position: relative;
}

.v-sparkline path {
  stroke: #35CDCD;
  stroke-width: 2px;
}

.v-sparkline circle {
  fill: #35CDCD;
  stroke: none;
  /* Tamaño del círculo (puedes ajustarlo según sea necesario) */
}

.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
}

svg {
  width: 100%;
  height: 100%;
}

polyline {
  stroke-linejoin: round;
  stroke-linecap: round;
}

.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
