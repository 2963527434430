<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  created() {
    const isAuthenticated = sessionStorage.getItem('token');
    if (!isAuthenticated && this.$route.path !== '/') {
      this.$router.push('/');
    }
  }
};
</script>
