<template>
  <div>
    <h1 class="mx-5 mt-0 my-3">Perfil {{ nombre }} {{ apellido }}</h1>
    <v-card-text v-if="cliente">
      <v-row>
        <v-col cols="12" sm="6">
          <strong>Nombre: </strong>{{ cliente.nombre }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Primer Apellido: </strong>{{ cliente.apellido }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Segundo Apellido: </strong>{{ cliente.apellido2 }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Teléfono: </strong>{{ cliente.telefono }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Fecha de Nacimiento: </strong>{{ cliente.fechadeNacimiento.substring(0, 10) }}
        </v-col>
        <!--<v-col cols="12" sm="6">
          <strong>Direccion: </strong>{{ cliente.colonia }}
        </v-col>-->
        <v-col cols="12" sm="6">
          <strong>Sexo: </strong>{{ cliente.sexo }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>INE: </strong>{{ cliente.ine }}
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-else>
      <v-alert color="yellow darken-2">
        <v-icon>mdi-alert</v-icon> No se seleccionó un cliente.
      </v-alert>
    </v-card-text>

    <div class="linea-horizontal"></div>

    <h1 class="mx-5 mt-10">Valoración Inicial</h1>
    <v-card-text v-if="valoracionInicial">
      <v-row>
        <v-col cols="12" sm="6">
          <strong>Porque quieres bajar de peso: </strong>{{ valoracionInicial.porque }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Cuanto te gustaría bajar: </strong>{{ valoracionInicial.cuanto }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Detalles: </strong>{{ valoracionInicial.detalles }}
        </v-col>
        <!--<v-col cols="12" sm="6">
          <strong>Peso Actual: </strong>{{ valoracionInicial.peso }}
        </v-col>-->
        <v-col cols="12" sm="6">
          <strong>Medidas de cintura: </strong>{{ valoracionInicial.cintura }}
        </v-col>
        <!--<v-col cols="12" sm="6">
          <strong>Estatura: </strong>{{ valoracionInicial.estatura }}
        </v-col>-->
        <v-col cols="12" sm="6">
          <strong>Cadera: </strong>{{ valoracionInicial.cadera }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Comentarios: </strong>{{ valoracionInicial.comentarios }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Programa Nutricional: </strong>{{ valoracionInicial.programaNutricional }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Duración del programa Nutricional: </strong>{{ valoracionInicial.duracionprogramaNutricional }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Programa De Estética: </strong>{{ valoracionInicial.programaEstetica }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Duración del programa de Estética: </strong>{{ valoracionInicial.duracionprogramaEstetica }}
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-else-if="valoracionInicial === null">
      <v-alert color="yellow darken-2">
        <v-icon>mdi-alert</v-icon> No cuenta con valoración Inicial.
      </v-alert>
    </v-card-text>
    <v-card-text v-else>
      <v-alert color="yellow darken-2">
        <v-icon>mdi-alert</v-icon> Error al obtener la valoración.
      </v-alert>
    </v-card-text>

    <div class="linea-horizontal"></div>

    <h1 class="mx-5 mt-10">Valoración Final</h1>
    <v-card-text v-if="valoracionFinal">
      <v-row>
        <v-col cols="12" sm="6">
          <strong>Porque quieres bajar de peso: </strong>{{ valoracionFinal.porque }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Cuanto te gustaría bajar: </strong>{{ valoracionFinal.cuanto }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Detalles: </strong>{{ valoracionFinal.detalles }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Peso Actual: </strong>{{ valoracionFinal.peso }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Medidas de cintura: </strong>{{ valoracionFinal.cintura }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Estatura: </strong>{{ valoracionFinal.estatura }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Cadera: </strong>{{ valoracionFinal.cadera }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Comentarios: </strong>{{ valoracionFinal.comentarios }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Programa Nutricional: </strong>{{ valoracionFinal.programaNutricional }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Duración del programa Nutricional: </strong>{{ valoracionFinal.duracionprogramaNutricional }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Programa De Estética: </strong>{{ valoracionFinal.programaEstetica }}
        </v-col>
        <v-col cols="12" sm="6">
          <strong>Duración del programa de Estética: </strong>{{ valoracionFinal.duracionprogramaEstetica }}
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-else-if="valoracionFinal === null">
      <v-alert color="yellow darken-2">
        <v-icon>mdi-alert</v-icon> No cuenta con valoración Final.
      </v-alert>
    </v-card-text>
    <v-card-text v-else>
      <v-alert color="yellow darken-2">
        <v-icon>mdi-alert</v-icon> Error al obtener la valoración.
      </v-alert>
    </v-card-text>
    <div class="linea-horizontal"></div>

    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
import { API_HOST } from '../utils/constants.js';

export default {
  props: {
    idCliente: {
      type: Number,
    }
  },
  data() {
    return {
      cliente: null,
      nombre: "..",
      apellido: "..",
      valoracionInicial: null,
      valoracionFinal: null,

      snackbar: false,
      snackbarText: '',
      timeout: 3000,
    };
  },
  mounted() {
    if (this.idCliente) {
      this.fetchPerfil(this.idCliente);
      this.fetchValoracion(this.idCliente);
    }
  },
  watch: {
    idCliente(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.fetchPerfil(newVal);
        this.fetchValoracion(newVal);
      }
    }
  },
  methods: {
    async fetchPerfil(idCliente) {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/clientes/${idCliente}`);
        if (response.data && response.data.body && typeof response.data.body === 'object') {
          this.cliente = response.data.body;
          this.nombre = response.data.body.nombre;
          this.apellido = response.data.body.apellido;
        } else {
          this.snackbarText = 'La respuesta del servidor no contiene datos válidos';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener datos del cliente';
        this.snackbar = true;
      }
    },

    async fetchValoracion(idCliente) {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/clientes/valoracion/${idCliente}`);
        if (response.data && response.data.body && Array.isArray(response.data.body)) {
          // Inicializar valoraciones a null por defecto
          this.valoracionInicial = null;
          this.valoracionFinal = null;

          // Asignar valoraciones si existen
          response.data.body.forEach(valoracion => {
            if (valoracion.tipoValoracion === 0) {
              this.valoracionInicial = valoracion;
            } else if (valoracion.tipoValoracion === 1) {
              this.valoracionFinal = valoracion;
            }
          });

        } else {
          this.snackbarText = 'La respuesta del servidor no contiene datos válidos';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener la valoración del cliente';
        this.snackbar = true;

        this.valoracionInicial = 'error';
        this.valoracionFinal = 'error';
      }
    }
  }
};
</script>

<style scoped>
.linea-horizontal {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 2em 0;
}
</style>
