<template>
  <div>
    <h1 class="mx-5 mt-0">Valoraciónes {{ nombre }} {{ apellido }}</h1>

    <!-- Mostrar mensaje si no se seleccionó un cliente -->
    <v-col cols="12" v-if="!idCliente">
      <v-alert color="yellow darken-2">
        <v-icon>mdi-alert</v-icon> No se encontro un cliente.
      </v-alert>
    </v-col>


    <!-- Mostrar formulario según el tipo de valoración -->
    <template v-else>
      <!-- Selección del tipo de valoración -->
      <v-col cols="12" v-if="!tipoValoracion">
        <v-card-title>
          <h4 class="mx-0 mt-10">Selecciona el Tipo de Valoración</h4>
        </v-card-title>
        <v-card-text>
          <v-select v-model="tipoValoracion" :items="items4" label="Tipo de Valoración" outlined color="#35CDCD"
            :rules="[v => !!v || 'Debes seleccionar un tipo de valoración']" @change="checkValoraciones"></v-select>
        </v-card-text>
      </v-col>

      <template v-else>
        <v-card-title color="#35CDCD">
          <h4 class="mx-2 mt-10">{{ tipoValoracion }}</h4>
        </v-card-title>
        <!-- Dialogo para mostrar alerta -->
        <v-dialog v-model="showErrorModal" max-width="400">
          <v-card>
            <v-card-text class="text-center">
              <span class="headline">¡Alerta!</span>
              <div style="margin-top: 30px;"> <!-- Espacio adicional entre el span y el párrafo -->
                <p>{{ errorMessage }}</p>
              </div>
              <v-card-actions class="justify-center">
                <v-btn color="#35CDCD" text @click="showErrorModal = false">Entendido</v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </template>

    <!-- Modal para valoración inicial -->
    <!-- Template para valoración inicial -->

    <template v-if="tipoValoracion === 'Valoración Inicial'">
      <!-- Campos para valoración inicial -->
      <v-row class="mx-3">
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="motivoBajarPeso" outlined color="#35CDCD" label="Porque quieres bajar de peso"
            type="text" required></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="pesoObjetivo" outlined color="#35CDCD" label="Cuanto te gustaría bajar" type="number"
            min="1" suffix="Kilos" required></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea v-model="detalles" outlined color="#35CDCD" name="input-7-4" label="Detalles"></v-textarea>
        </v-col>
        <!--<v-col cols="12" sm="6" md="6">
              <v-text-field v-model="pesoActual" outlined color="#35CDCD" label="Peso Actual" type="number"
                suffix="Kilos" min="1" required></v-text-field>
            </v-col>-->
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="medidasCintura" outlined color="#35CDCD" label="Medidas de cintura" type="number"
            min="1" suffix="cms" required></v-text-field>
        </v-col>
        <!--<v-col cols="12" sm="6" md="6">
              <v-text-field v-model="estatura" outlined color="#35CDCD" label="Estatura" type="number" suffix="cms"
                min="1" required></v-text-field>
            </v-col>-->
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="cadera" outlined color="#35CDCD" label="Cadera" type="number" suffix="cms" min="1"
            required></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select v-model="programaNutricional" outlined color="#35CDCD" :items="items1"
            label="Programa Nutricional"></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="duracionProgramaNutricional" outlined color="#35CDCD" label="Duración del programa"
            min="1" type="number" suffix="Meses" required></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select v-model="programaEstetica" outlined color="#35CDCD" :items="items3"
            label="Programa De Estética"></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="duracionProgramaEstetica" outlined color="#35CDCD" label="Duración del programa"
            min="1" type="number" suffix="Meses" required></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea v-model="comentarios" color="#35CDCD" outlined name="input-7-4" label="Comentarios"></v-textarea>
        </v-col>
        <v-icon @click="limpiarFormulario; tipoValoracion = ''" color="#000000" class="mx-4 mb-8">
          mdi-arrow-left
        </v-icon>
        <v-row class="mt-0" justify="center">
          <v-btn color="#35CDCD" @click="handleSubmit" dark class="mx-4 mb-8">
            Guardar
          </v-btn>
        </v-row>
      </v-row>
      <!-- ... -->
    </template>


    <!-- Template para valoración final -->
    <template v-if="tipoValoracion === 'Valoración Final'">
      <!-- Campos para valoración final -->
      <v-row class="mx-3">
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="motivoBajarPeso" outlined color="#35CDCD" label="Porque quieres bajar de peso"
            type="text" required></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="pesoObjetivo" outlined color="#35CDCD" label="Cuanto te gustaría bajar" type="number"
            min="1" suffix="Kilos" required></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea v-model="detalles" outlined color="#35CDCD" name="input-7-4" label="Detalles"></v-textarea>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="pesoActual" outlined color="#35CDCD" label="Peso Actual" type="number" suffix="Kilos"
            min="1" required></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="medidasCintura" outlined color="#35CDCD" label="Medidas de cintura" type="number"
            min="1" suffix="cms" required></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="estatura" outlined color="#35CDCD" label="Estatura" type="number" suffix="cms" min="1"
            required></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="cadera" outlined color="#35CDCD" label="Cadera" type="number" suffix="cms" min="1"
            required></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select v-model="programaNutricional" outlined color="#35CDCD" :items="items1"
            label="Programa Nutricional"></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="duracionProgramaNutricional" outlined color="#35CDCD" label="Duración del programa"
            min="1" type="number" suffix="Meses" required></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select v-model="programaEstetica" outlined color="#35CDCD" :items="items3"
            label="Programa De Estética"></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="duracionProgramaEstetica" outlined color="#35CDCD" label="Duración del programa"
            min="1" type="number" suffix="Meses" required></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea v-model="comentarios" color="#35CDCD" outlined name="input-7-4" label="Comentarios"></v-textarea>
        </v-col>
        <v-icon @click="limpiarFormulario; tipoValoracion = ''" color="#000000" class="mx-4 mb-8">
          mdi-arrow-left
        </v-icon>
        <v-row class="mt-0" justify="center">
          <v-btn color="#35CDCD" @click="handleSubmit" dark class="mx-4 mb-8">
            Guardar
          </v-btn>
        </v-row>
      </v-row>
      <!-- ... -->
    </template>

    <!-- Snackbar para mensajes -->
    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>


<script>
import axios from 'axios';
import { API_HOST } from '../utils/constants.js';

export default {
  props: {
    idCliente: {
      type: Number,
    }
  },
  data() {
    return {
      items1: ['Dieta Inicial', 'Dieta de Mantenimiento'],
      items3: ['CONSULTA-NUTRICIONAL', 'NUT+T-SHAPE', 'SLIM UP', 'T-SHAPE', 'ULTRAFORMER'],
      items4: ['Valoración Inicial', 'Valoración Final'],

      motivoBajarPeso: '',
      pesoObjetivo: '',
      detalles: '',
      pesoActual: '',
      medidasCintura: '',
      estatura: '',
      cadera: '',
      programaNutricional: '',
      duracionProgramaNutricional: '',
      programaEstetica: '',
      duracionProgramaEstetica: '',
      comentarios: '',
      snackbar: false,
      snackbarText: '',
      errorMessage: '', // Variable para el mensaje de error
      showErrorModal: false, // Control del modal de error

      nombre: '..',
      apellido: '..',
      valoracio: null,
      tipoValoracion: '', // Nuevo dato para almacenar el tipo de valoración seleccionado

      timeout: 3000,

      clienteAEditar: null,
      valoracionFinal: null,
      valoracionInicial: null,
      valoraciones: [],

      showValoracionInicialModal: false,

      showValoracionFinalModal: false,
    }
  },
  mounted() {
    if (this.idCliente) {
      this.fetchPerfil(this.idCliente);
      this.fetchValoracion(this.idCliente);
    }
  },
  methods: {
    // Validar campos antes de enviar el formulario
    validarFormulario() {
      if (this.tipoValoracion == 'Valoración Inicial') {
        if (this.motivoBajarPeso && this.pesoObjetivo && this.medidasCintura && this.cadera
          && this.programaNutricional && this.duracionProgramaNutricional && this.programaEstetica && this.duracionProgramaEstetica) {
          // Si todos los campos están llenos, el formulario es válido
          this.errorMessage = '';
          return true;
        } else {
          // Si alguno de los campos está vacío, mostrar mensaje de error
          this.errorMessage = 'Por favor, complete todos los campos obligatorios.';
          this.showErrorModal = true;
          return false;
        }
      } else if (this.tipoValoracion == 'Valoración Final') {
        if (this.motivoBajarPeso && this.pesoObjetivo && this.pesoActual && this.medidasCintura && this.estatura && this.cadera
          && this.programaNutricional && this.duracionProgramaNutricional && this.programaEstetica && this.duracionProgramaEstetica) {
          // Si todos los campos están llenos, el formulario es válido
          this.errorMessage = '';
          return true;
        } else {
          // Si alguno de los campos está vacío, mostrar mensaje de error
          this.errorMessage = 'Por favor, complete todos los campos obligatorios.';
          this.showErrorModal = true;
          return false;
        }
      }

    },

    async fetchAgregarValoracion(idCliente) {
      if (this.validarFormulario()) {
        try {
          let tipoValoracion;
          let datosValoracion;

          if (this.tipoValoracion == 'Valoración Inicial') {
            tipoValoracion = 0;
            datosValoracion = {
              idValoracion: 0,
              idCliente: idCliente,
              porque: this.motivoBajarPeso,
              cuanto: this.pesoObjetivo,
              detalles: this.detalles,
              peso: 0,
              cintura: this.medidasCintura,
              estatura: 0,
              cadera: this.cadera,
              programaNutricional: this.programaNutricional,
              duracionProgramaNutricional: this.duracionProgramaNutricional,
              programaEstetica: this.programaEstetica,
              duracionProgramaEstetica: this.duracionProgramaEstetica,
              comentarios: this.comentarios,
            };
          } else if (this.tipoValoracion == 'Valoración Final') {
            tipoValoracion = 1;
            datosValoracion = {
              idValoracion: 0,
              idCliente: idCliente,
              porque: this.motivoBajarPeso,
              cuanto: this.pesoObjetivo,
              detalles: this.detalles,
              peso: this.pesoActual,
              cintura: this.medidasCintura,
              estatura: this.estatura,
              cadera: this.cadera,
              programaNutricional: this.programaNutricional,
              duracionProgramaNutricional: this.duracionProgramaNutricional,
              programaEstetica: this.programaEstetica,
              duracionProgramaEstetica: this.duracionProgramaEstetica,
              comentarios: this.comentarios,
            };
          }

          await axios.post(`https://${API_HOST}/api/vista/clientes/valoracion`, {
            ...datosValoracion,
            tipoValoracion: tipoValoracion,
          });

          this.mostrarSnackbar('Valoración agregada correctamente');
        } catch (error) {
          error
          this.snackbarText = 'Error al agregar valoración';
          this.snackbar = true;
        }
      }
    },

    limpiarFormulario() {
      this.motivoBajarPeso = '';
      this.pesoObjetivo = '';
      this.detalles = '';
      this.pesoActual = '';
      this.medidasCintura = '';
      this.estatura = '';
      this.cadera = '';
      this.programaNutricional = '';
      this.duracionProgramaNutricional = '';
      this.programaEstetica = '';
      this.duracionProgramaEstetica = '';
      this.comentarios = '';
    },
    mostrarSnackbar(texto) {
      this.snackbarText = texto;
      this.snackbar = true;
    },
    async fetchPerfil(idCliente) {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/clientes/${idCliente}`);
        if (response.data && response.data.body && typeof response.data.body === 'object') {
          this.cliente = response.data.body;
          this.nombre = response.data.body.nombre;
          this.apellido = response.data.body.apellido;
        } else {
          this.snackbarText = 'La respuesta del servidor no contiene datos válidos';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener datos del cliente';
        this.snackbar = true;
      }
    },
    handleSubmit() {
      this.fetchAgregarValoracion(this.idCliente);
    },

    //FUNCION PARA AGREGAR CLIENTES
    editItem(item) {
      this.clienteAEditar = item.idCliente;
      this.fetchValoracion(this.clienteAEditar);
    },

    async fetchValoracion(idCliente) {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/clientes/valoracion/${idCliente}`);
        if (response.data && response.data.body && typeof response.data.body === 'object') {
          this.valoraciones = response.data.body;
          if (this.valoraciones.length > 0) {
            this.valoraciones.forEach(valoracion => {
              if (valoracion.tipoValoracion === 0) {
                this.valoracionInicial = valoracion;
              } else if (valoracion.tipoValoracion === 1) {
                this.valoracionFinal = valoracion;
              }
            });
          }

        } else {
          this.snackbarText = 'La respuesta del servidor no contiene datos válidos';
          this.snackbar = true;
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.valoracionInicial = null; // Mostrar mensaje de que no hay valoración
          this.valoracionFinal = null; // Mostrar mensaje de que no hay valoración
        } else {
          error
          this.snackbarText = 'Error al obtener datos de la valoración';
          this.snackbar = true;
        }
      }
    },

    checkValoraciones() {
      if (this.tipoValoracion === 'Valoración Inicial') {
        this.motivoBajarPeso = this.valoracionInicial?.porque || '';
        this.pesoObjetivo = this.valoracionInicial?.cuanto || '';
        this.detalles = this.valoracionInicial?.detalles || '';
        //this.pesoActual = this.valoracionInicial?.peso || '';
        this.medidasCintura = this.valoracionInicial?.cintura || '';
        //this.estatura = this.valoracionInicial?.estatura || '';
        this.cadera = this.valoracionInicial?.cadera || '';
        this.programaNutricional = this.valoracionInicial?.programaNutricional || '';
        this.duracionProgramaNutricional = this.valoracionInicial?.duracionprogramaNutricional || '';
        this.programaEstetica = this.valoracionInicial?.programaEstetica || '';
        this.duracionProgramaEstetica = this.valoracionInicial?.duracionprogramaEstetica || '';
        this.comentarios = this.valoracionInicial?.comentarios || '';
      } else if (this.tipoValoracion === 'Valoración Final') {
        this.motivoBajarPeso = this.valoracionFinal?.porque || '';
        this.pesoObjetivo = this.valoracionFinal?.cuanto || '';
        this.detalles = this.valoracionFinal?.detalles || '';
        this.pesoActual = this.valoracionFinal?.peso || '';
        this.medidasCintura = this.valoracionFinal?.cintura || '';
        this.estatura = this.valoracionFinal?.estatura || '';
        this.cadera = this.valoracionFinal?.cadera || '';
        this.programaNutricional = this.valoracionFinal?.programaNutricional || '';
        this.duracionProgramaNutricional = this.valoracionFinal?.duracionprogramaNutricional || '';
        this.programaEstetica = this.valoracionFinal?.programaEstetica || '';
        this.duracionProgramaEstetica = this.valoracionFinal?.duracionprogramaEstetica || '';
        this.comentarios = this.valoracionFinal?.comentarios || '';
      }
    },


  }
};
</script>


<style scoped>
/* Estilos opcionales si los necesitas */
</style>
