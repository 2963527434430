<template>
  <div>
    <h1 class="mx-5 mt-0 pa-8">Agregar Paquete</h1>
    <v-container fluid>

      <v-row>
        <v-col cols="12" md="4">
          <v-radio-group v-model="selectedService" column required @change="resetSubMenus">
            <v-radio v-for="(group, firstWord) in groupedServices" color="#35CDCD" :key="firstWord" :label="firstWord"
              :value="firstWord">
              <v-list dense>
                <v-list-item v-for="service in group" :key="service.idServicio">
                  <v-list-item-content>
                    <v-list-item-title>

                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-radio>
          </v-radio-group>

        </v-col>
        <v-col cols="12" md="8">
          <v-select v-model="selectedOption" :items="options" outlined prepend-icon="mdi-timetable" color="#35CDCD"
            label="Sesiones" item-text="label" item-value="value"></v-select>

          <v-select v-model="empleadoSeleccionado" text-color="#000" color="#35CDCD" outlined
            prepend-icon="mdi-account-box-outline"
            :items="userRole === 'admin' ? empleados : empleados.filter(empleado => empleado.text !== 'Dulce')"
            label="Selecciona un empleado">
          </v-select>

          <!-- Select para método de pago -->
          <v-select v-model="pago" outlined color="#35CDCD" prepend-icon="mdi-cash-multiple" :items="opcionesPago"
            required label="Metodo de Pago"></v-select>

          <!-- Select adicional según opción seleccionada -->
          <v-row>
            <v-col cols="12">
              <v-select v-model="opcionesglp1" color="#35CDCD" outlined prepend-icon="mdi-needle" :items="opcionesglp"
                label="NUT:" :disabled="isDisabled" @change="handleChange"></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="linea-horizontal"></div>

      <v-col cols="12">
        <v-btn @click="abrirModal2" color="#35CDCD" dark block large>
          <!-- 'block' para que ocupe todo el ancho disponible -->
          Siguiente
        </v-btn>
      </v-col>

      <v-dialog v-model="modalVisible3" persistent max-width="1000">
        <v-card>
          <v-btn icon class="close-button" @click="cerrarModal3">
            <v-icon color="red">mdi-plus-box</v-icon>
          </v-btn>
          <!-- Contenido de tu diálogo aquí -->
          <v-card-text class="text-center">
            <Agenda />
          </v-card-text>

          <!-- Botón de cerrar en la esquina superior derecha -->
          <v-btn icon class="close-button" @click="cerrarModal3">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card>
      </v-dialog>

      <!-- Diálogo para ingresar INE -->
      <v-dialog v-model="modalVisible2" persistent max-width="390">
        <v-card>
          <v-card-title>Necesario</v-card-title>
          <v-card-text>
            ¿Tiene el concentimiento del cliente?

            Debera de ingresar si INE para poder seguir con el proceso.
            <v-col>
              <v-text-field label="Ingrese su INE" color="#35CDCD" id="ine" v-model="ine" type="text" required
                :rules="[v => !v || v.length <= 20 || 'Debe tener hasta 20 dígitos']"></v-text-field>
            </v-col>
          </v-card-text>
          <v-card-actions><v-spacer></v-spacer>
            <v-btn color="red" text @click="modalVisible2 = false">Cerrar</v-btn>
            <v-btn color="#35CDCD" text @click="handleIneSubmit()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Diálogo para guardar paquete si ya tiene INE -->
      <v-dialog v-model="modalVisible4" persistent max-width="390">
        <v-card>
          <v-card-title>Guardar Paquete</v-card-title>
          <v-card-text>
            ¿Está seguro de guardar el paquete?
          </v-card-text>
          <v-card-text>
            Paciente: {{ this.nombre }} {{ this.apellido }} {{ this.apellido2 }}
          </v-card-text>
          <v-card-actions><v-spacer></v-spacer>
            <v-btn color="red" text @click="modalVisible4 = false">Cancelar</v-btn>
            <v-btn color="#35CDCD" text @click="handleSubmit()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
    <!-- Dialogo para mostrar alerta -->
    <v-dialog v-model="alertaformulario" max-width="400">
      <v-card>
        <v-card-text class="text-center pa-4">
          <span class="headline">¡Alerta!</span>
          <div style="margin-top: 30px;"> <!-- Espacio adicional entre el span y el párrafo -->
            <p>Por favor, complete todos los campos obligatorios.</p>
          </div>
          <v-card-actions class="justify-center">
            <v-btn color="#35CDCD" text @click="alertaformulario = false">Entendido</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
import Agenda from '@/views/AgendaView.vue';
import { API_HOST } from '../utils/constants.js';

export default {
  props: {
    idCliente: {
      type: Number,
    }
  },
  components: {
    Agenda,
  },
  data() {
    return {
      cliente: null,
      services: [],
      groupedServices: {},
      selectedService: null,
      selectedOption: null,
      selectedOption2: null,
      options: [],

      opcionesModal: [], // Para las opciones específicas del producto seleccionado
      opcionesglp1: null,
      opcionSubMenu1: null,
      empleadoSeleccionado: null,
      opcionesEmpleados: [],
      empleados: [],// Para el empleado seleccionado
      pago: null,

      opcionesglp: [
        { text: 'NUT 20', value: '1' },
        { text: 'NUT 40', value: '2' },
        { text: 'NUT 60', value: '3' },
      ],
      // opcionesEmpleados: [
      //   { text: 'Nutriologa', value: 'nutriologa' },
      //   { text: 'Recepcionista', value: 'recepcionista' },
      //   { text: 'Cosmetologa', value: 'cosmetologa' },
      //   // Agrega más opciones según sea necesario
      // ],
      opcionesPago: [
        { text: 'Efectivo', value: 'efectivo' },
        { text: 'Credito', value: 'credito' },
        { text: 'Debito', value: 'debito' },
        { text: 'Transferencia', value: 'transferencia' },
      ],

      formulariosAdicionales: [], // Para almacenar formularios adicionales dinámicamente
      modal: false,
      modal2: false,
      modal3: false,
      time: null,
      date: null,
      modalVisible: false,
      modalVisible3: false,
      menu2: false,
      attrs: {},
      modalVisible2: false, // Variable para controlar la visibilidad del segundo modal
      modalVisible4: false,

      inemodel: false,
      ine: null,
      alertaformulario: false,
      totalcitas: null,
      snackbar: false,
      snackbarText: '',
      timeout: 3000,

      idCita: 0,
      idServicio: '',
      idEmpleado: '',
      fecha: '',
      hora: '',
      metodoPago: '',
      idProducto: '',
      createdBy: '',
      createdDate: '',
      duracion: '',
      idclienteactualizar: '',

      nombre: '',
      apellido: '',
      apellido2: '',

      userRole: sessionStorage.getItem('role'), // Suponiendo que ya tienes el rol en localStorage

    };
  },
  watch: {
    /*selectedOption(newValue) {
      // Reiniciar el valor del otro select cuando se cambia la opción seleccionada en los radios
      if (newValue === 'GLP') {
        this.opcionSubMenu2 = null;
      } else if (newValue === 'SLIMUP') {
        this.opcionSubMenu1 = null;
      }
    }*/
    selectedService() {
      this.resetGLP();
    }
  },
  mounted() {
    if (this.idCliente) {
      this.fetchPerfil(this.idCliente);
    }
    this.fetchEmpleados();
  },

  created() {
    this.fetchServices();
  },

  methods: {
    resetGLP() {
      this.opcionesglp1 = null;
    },
    resetForm() {
      this.selectedOption = null;
      this.empleadoSeleccionado = null;
      this.pago = null;
      this.opcionesglp1 = null;
    },

    async handleIneSubmit() {
      // Verifica si todos los campos requeridos están completos
      if (!this.selectedService || !this.selectedOption || !this.empleadoSeleccionado || !this.pago) {
        this.alertaformulario = true;
        return;
      }

      // Continúa con el resto de la lógica
      await this.fetchAgregarIne();
      this.$emit('guardar-paquete');
    },

    async handleSubmit() {
      // Verifica si todos los campos requeridos están completos
      if (!this.selectedService || !this.selectedOption || !this.empleadoSeleccionado || !this.pago) {
        this.alertaformulario = true;
        return;
      }
      // Continúa con el resto de la lógica
      await this.fetchAgregarPaquete();
      this.$emit('guardar-paquete');
    },

    async fetchAgregarIne() {
      try {
        const response = await axios.put(`https://${API_HOST}/api/vista/clientes/ine/${this.idCliente}`, {
          ine: this.ine
        });
        if (response.data && response.data.message) {
          this.cliente.ine = this.ine;
          this.modalVisible2 = false;
          this.fetchAgregarPaquete();
        } else {
          this.snackbarText = 'Error al agregar INE al perfil del cliente';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al agregar INE al perfil del cliente';
        this.snackbar = true;
      }
    },

    //METODOS Y FUNCIONES NECESARIOS PARA MOSTRAR CAMPOS Y TRAER INFORMACION A CADA UNO DE LOS MENUS
    async fetchPerfil(idCliente) {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/clientes/${idCliente}`);
        if (response.data && response.data.body && typeof response.data.body === 'object') {
          this.cliente = response.data.body;
          this.nombre = response.data.body.nombre;
          this.apellido = response.data.body.apellido;
          this.apellido2 = response.data.body.apellido2;
          this.ine = response.data.body.ine;

        } else {
          this.snackbarText = 'La respuesta del servidor no contiene datos válidos';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener datos del cliente';
        this.snackbar = true;
      }
    },

    async fetchventas(idCliente) {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/ventas/${idCliente}`);
        if (response.data && response.data.body && typeof response.data.body === 'object') {
          //this.idclienteactualizar = idCliente;
          this.cliente = response.data.body;
          this.nombre = response.data.body.nombre;
          this.apellido = response.data.body.apellido;
        } else {
          this.snackbarText = 'La respuesta del servidor no contiene datos válidos';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener datos del cliente';
        this.snackbar = true;
      }
    },

    fetchEmpleados() {
      axios.get(`https://${API_HOST}/api/vista/empleados`)
        .then(response => {
          let data = response.data.body;
          if (Array.isArray(data)) {
            this.empleados = data.map(empleados => ({
              text: empleados.puesto, // Nombre del empleado o dato relevante para mostrar
              value: empleados.idEmpleado,   // Valor a enviar cuando se selecciona el empleado
            }));
          } else {
            this.snackbarText = 'La respuesta de la API no es un arreglo válido';
            this.snackbar = true;
          }
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al obtener los empleados';
          this.snackbar = true;
        });
    },

    resetSubMenus() {
      this.selectedOption = null;
      this.options = this.groupedServices[this.selectedService].map(service => ({
        label: `${service.nombre} --- Sesiones: ${service.duracion} --- Costo $${service.costo}`,
        value: service.idServicio,
        duracion: service.duracion
      }));
      this.resetGLP(); // Llama a la función resetGLP aquí
      this.resetForm(); //  Limpia los campos del formulario estableciendo
    },

    async fetchServices() {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/servicios`);
        const data = response.data.body;
        if (Array.isArray(data)) {
          this.services = data;
          this.groupedServices = this.groupByName(this.services);
        } else {
          this.snackbarText = 'La respuesta de la API no es válida';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error en los servicios';
        this.snackbar = true;
      }
    },

    groupByName(services) {
      return services.reduce((groups, service) => {
        const firstWord = service.nombre.split(' ')[0];
        if (!groups[firstWord]) {
          groups[firstWord] = [];
        }
        groups[firstWord].push(service);
        return groups;
      }, {});
    },

    abrirModal() {
      this.modalVisible = true;
    },
    abrirModal3() {
      this.modalVisible3 = true;
    },
    abrirModal2() {
      if (!this.selectedService || !this.selectedOption ||
        !this.empleadoSeleccionado || !this.pago) {
        this.alertaformulario = true;
        return;
      }
      if (this.ine) {
        this.modalVisible4 = true;
      } else {
        this.modalVisible2 = true;
      }
    },
    cerrarModal() {
      this.modalVisible = false;
    },
    cerrarModal2() {
      this.modalVisible2 = false;
    },
    cerrarModal3() {
      this.modalVisible3 = false;
      this.selectedService = null;
      this.selectedOption = null;
      this.empleadoSeleccionado = null;
      this.pago = null;
      this.opcionesglp1 = null;

    },

    guardarOpcion() {
      //console.log('Opción seleccionada:', this.opcionSubMenu);
      this.cerrarModal();
      this.opcionSeleccionada = '';
      this.opcionSubMenu = '';
    },
    agregarFormularioAdicional() {
      this.formulariosAdicionales.push({});
    },
    handleChange() {
      if (this.isDisabled) {
        this.opcionesglp1 = null; // Cambia el valor a nulo si está deshabilitado
      }
    },

    //SECCION PARA LA CREACION DE LOS PAQUETE/VENTAS/CITAS E INSERSION DE DATOS DENTRO DE LA TABLA CORRESPONDIENTE
    async fetchAgregarPaquete() {
      if (!this.selectedService || !this.selectedOption ||
        !this.empleadoSeleccionado || !this.pago) {
        this.alertaformulario = true;
        return;
      }
      try {
        const fechaActual = new Date();
        // Fecha
        const dia = String(fechaActual.getDate()).padStart(2, '0');
        const mes = String(fechaActual.getMonth() + 1).padStart(2, '0');
        const año = fechaActual.getFullYear();

        const fecha = `${año}-${mes}-${dia}`;
        // Hora
        const hora = String(fechaActual.getHours()).padStart(2, '0');
        const minutos = String(fechaActual.getMinutes()).padStart(2, '0');
        const segundos = String(fechaActual.getSeconds()).padStart(2, '0');

        const horaActual = `${hora}:${minutos}:${segundos}`;

        await axios.post(`https://${API_HOST}/api/vista/ventas/citas`, {

          idServicio: this.selectedOption,
          idCliente: this.idCliente,
          idEmpleado: this.empleadoSeleccionado,
          fecha: fecha,
          hora: horaActual,
          metodoPago: this.pago,
          idProducto: this.opcionesglp1,
          createdBy: sessionStorage.getItem('user'),
          createdDate: new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        });
        //this.fetchventas(this.idclienteactualizar);
        // Limpiar campos para nuevas variables
        this.selectedService = null;
        this.selectedOption = null;
        this.empleadoSeleccionado = null;
        this.pago = null;
        this.opcionesglp1 = null;

        this.snackbarText = 'Paquete creado exitosamente';
        this.snackbar = true;
      } catch (error) {
        error
        this.snackbarText = 'Error al enviar los datos del paquete';
        this.snackbar = true;
      }
    }


  },

  computed: {
    selectedServiceDetails() {
      if (!this.selectedOption) return null;
      return this.groupedServices[this.selectedService].find(service => service.idServicio === this.selectedOption);
    },
    isDisabled() {
      return this.selectedService !== 'CONSULTA-NUTRICIONAL' && this.selectedService !== 'NUT+TSHAPE';
    },
  },


}

</script>

<style scoped>
.smaller-picker .v-picker__body {
  font-size: 12px;
  /* Ajusta el tamaño del texto en los pickers */
  line-height: 1.5;
  /* Ajusta el espaciado entre líneas si es necesario */
  height: 30px;
}

.linea-horizontal {
  border-top: 1px solid #ccc;
  margin: 20px 0;
  /* Ajusta el margen según necesites */
}
</style>