<template>
    <v-dialog v-model="dialog" max-width="500">
        <v-card>
            <v-card-title class="headline red-text">{{ title }}</v-card-title>
            <v-card-text>{{ message }}</v-card-text>
            <v-card-actions>
                <v-btn color="black" text @click="closeAlert">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'CustomAlert',
    props: {
        title: {
            type: String,
            default: 'Alerta'
        },
        message: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dialog: false
        };
    },
    methods: {
        closeAlert() {
            this.dialog = false;
        }
    }
};
</script>

<style scoped>
.red-text {
    color: #FF0000;
    /* Color rojo */
}
</style>