<template>
    <v-app>
        <v-container>
            <v-layout>
                <!-- Barra superior con logo -->
                <v-app-bar app color="#35CDCD" height="65px">
                    <div class="d-flex align-center">
                        <v-img src="@/assets/logo2.png" max-height="50" contain></v-img>
                        <v-toolbar-title class="text-h6 ml-2">Sweight</v-toolbar-title>
                    </div>
                    <v-spacer></v-spacer>
                    <h4>{{ this.user }}</h4>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <!-- Botón de cerrar sesión -->
                    <CerrarSesion> </CerrarSesion>
                </v-app-bar>
            </v-layout>

            <template v-if="this.role === 'admin'">
                <!-- Barra de navegación -->
                <template v-if="isMobileView">
                    <!-- Aqui la barra cuando se hace pequeña -->
                    <v-app-bar app color="#eeeeee" class="my-16">
                        <v-btn icon @click="drawer = !drawer">
                            <v-icon color="#000000">mdi-menu</v-icon>
                        </v-btn>
                    </v-app-bar>
                    <v-navigation-drawer v-model="drawer" app temporary>
                        <v-list>
                            <v-list-item v-for="(item, index) in itemsAdmin" :key="index" @click="selectItem(item)">
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-navigation-drawer>
                </template>

                <template v-else>
                    <!-- Aquí cuando la ventana es grande  -->
                    <v-app-bar app color="#eeeeee" class="my-16">
                        <v-container class="d-flex justify-space-between" flex>
                            <v-btn v-for="(item, index) in itemsAdmin" :key="index" depressed text
                                @click="goTo(item.route)"
                                :class="{ 'selected active-button': isRouteActive(item.route) }">
                                {{ item.text }}
                            </v-btn>
                        </v-container>
                    </v-app-bar>
                </template>
            </template>

            <template v-else-if="this.role === 'standard'">
                <!-- Barra de navegación -->
                <template v-if="isMobileView">
                    <!-- Aquí la barra cuando se hace pequeña -->
                    <v-app-bar app color="#eeeeee" class="my-16">
                        <v-btn icon @click="drawer = !drawer">
                            <v-icon color="#000000">mdi-menu</v-icon>
                        </v-btn>
                    </v-app-bar>
                    <v-navigation-drawer v-model="drawer" app temporary>
                        <v-list>
                            <v-list-item v-for="(item, index) in itemsStandard" :key="index" @click="selectItem(item)">
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-navigation-drawer>
                </template>

                <template v-else>
                    <!-- Aquí cuando la ventana es grande  -->
                    <v-app-bar app color="#eeeeee" class="my-16">
                        <v-container class="d-flex justify-center" flex>
                            <v-row class="d-flex justify-center">
                                <v-col cols="auto" v-for="(item, index) in itemsStandard" :key="index">
                                    <v-btn depressed text @click="goTo(item.route)"
                                        :class="{ 'selected active-button': isRouteActive(item.route), 'mx-2': true }">
                                        {{ item.text }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-app-bar>
                </template>
            </template>




            <!-- Fin de barra de navegación -->
        </v-container>
        <router-view />
    </v-app>
</template>

<script>
import CerrarSesion from '@/components/CerrarSesion.vue';

export default {
    name: 'PrincipalView',
    components: {
        CerrarSesion
    },
    data() {
        return {
            drawer: false,
            itemsAdmin: [
                { text: 'Clientes', route: '/vista/clientes' },
                { text: 'Agenda', route: '/vista/agenda' },
                { text: 'Inventario', route: '/vista/inventario' },
                { text: 'Gastos', route: '/vista/gastos' },
                { text: 'Ventas', route: '/vista/ventas' },
                { text: 'Corte', route: '/vista/corte' },
                { text: 'Comisiones', route: '/vista/comisiones' },
                { text: 'Administrar', route: '/vista/agregar' },
            ],
            itemsStandard: [
                { text: 'Clientes', route: '/vista/clientes' },
                { text: 'Agenda', route: '/vista/agenda' },
                { text: 'Inventario', route: '/vista/inventario' },
            ],
            selectedItem: null,
            windowWidth: window.innerWidth,
            user: null,
            role: null,

        };
    },
    mounted() {

        const userData = sessionStorage.getItem('user');
        const roleData = sessionStorage.getItem('role');
        if (userData && roleData) {
            try {
                const parsedDataUser = (userData);
                const parsedDataRole = (roleData);
                this.user = parsedDataUser;
                this.role = parsedDataRole;

            } catch (error) {
                console.error(error);
                this.user = null;
                this.role = null;
            }
        } else {
            this.user = null;
            this.role = null;
        }
    },
    beforeDestroy() {

        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        isMobileView() {
            return this.windowWidth <= 768; // Aquí puedes ajustar el ancho en píxeles según tus necesidades
        },
    },
    methods: {
        goTo(route) {
            if (this.$route.path !== route) {
                this.$router.push(route);
            }
        },
        isRouteActive(route) {
            return this.$route.path === route;
        },
        selectItem(item) {
            this.selectedItem = item;
            this.goTo(item.route);
            this.drawer = false; // Cierra el drawer al seleccionar un item
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        logout() {

        },

    },

    created() {
        window.addEventListener('resize', this.handleResize);
    },

};
</script>

<style scoped>
.active-button {
    background-color: rgba(0, 0, 0, 0.097);
}
</style>
