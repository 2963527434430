<template>
  <v-app>
    <v-row class="mx-0 my-0">
      <!-- Segunda tarjeta -->
      <v-col cols="12" md="2">
        <v-card class="pa-2">
          <v-row justify="center">
            <td>
              <div class="mb-4 mt-4">
                <h3>Etiquetas:</h3>
                <span class="etiqueta GLP">CONSULTA NUTRICIONAL</span>
                <span class="etiqueta TSHAPE">T-SHAPE</span>
                <span class="etiqueta SLIMUP">SLIM-UP</span>
                <span class="etiqueta FACIALES">FACIALES</span>
                <span class="etiqueta DEPILACION">DEPILACION</span>
                <span class="etiqueta FITPASS">FIT-PASS</span>
                <span class="etiqueta GLP_T_SHAPE">NUT + TSHAPE</span>
                <span class="etiqueta ULTRAFORMER">ULTRAFORMER</span>
                <span class="etiqueta VALORACION">VALORACION</span>
                <span class="etiqueta SESION">SESION TOMADA</span>
              </div>
            </td>
          </v-row>
        </v-card>
      </v-col>

      <!-- Tarjeta donde está el calendario -->
      <v-col cols="12" md="10">
        <v-card>
          <v-row class="mx-0 mt-0">
            <v-col>
              <v-sheet height="64">
                <v-toolbar flat>
                  <v-btn outlined class="mr-4" color="#35CDCD" @click="setToday">
                    Hoy
                  </v-btn>
                  <v-btn fab text small color="#35CDCD" @click="prev">
                    <v-icon small>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn fab text small color="#35CDCD" @click="next">
                    <v-icon small>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn outlined class="mr-4" color="#35CDCD" @click="changeView('day')">
                    Día
                  </v-btn>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="800">
                <v-calendar ref="calendar" v-model="focus" color="#35CDCD" :events="events" :event-color="getEventColor"
                  :type="type" @click:event="showEvent" @click:more="viewDay" @click:date="viewDay" :locale="'es'"
                  interval-height="80">
                  <template v-slot:more="props">
                    <v-btn @click="openMoreModal(props.date)"></v-btn>
                  </template>
                </v-calendar>

                <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
                  <v-card min-width="350px" flat>
                    <v-toolbar :color="selectedEvent.color">
                      <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                      <!-- Información del evento -->
                      <div>
                        <span v-html="selectedEvent.details"></span>
                        <v-divider></v-divider>
                        <span v-html="selectedEvent.hora"></span>
                      </div>
                      <br>
                      <!-- Información adicional sobre el creador y la fecha -->
                      <div>
                        Creado por {{ selectedEvent.createdBy }} el {{ selectedEvent.createdDate }}
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="#00000" @click="selectedOpen = false">
                        Cerrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>

              </v-sheet>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Modal de eventos -->
    <v-dialog v-model="modalOpen" max-width="600px">
      <v-card>
        <v-card-title>
          <span>Eventos del {{ (formattedDate) }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="modalOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item-group v-if="filteredEvents.length">
              <v-list-item v-for="event in filteredEvents" :key="event.id" :style="getItemStyle(event)">
                <v-list-item-content>
                  <v-list-item-title
                    :style="{ color: event.asistio === 1 ? 'grey' : getColorByServicio(event.details) }">
                    {{ event.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ event.hora }} - {{ event.details }}
                  </v-list-item-subtitle>
                  <v-list-item>
                    Creado por {{ event.createdBy }} el {{ event.createdDate }}
                  </v-list-item>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item v-else>
              <v-list-item-content>No hay eventos para este día.</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
</template>


<script>
import axios from 'axios';
import { API_HOST } from '../utils/constants.js';

export default {
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Mes",
      week: "Semana",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    modalOpen: false,
    filteredEvents: [], // Arreglo para almacenar los eventos filtrados por fecha
    events: [], // Arreglo para almacenar eventos
    formattedDate: '', // Fecha formateada para mostrar en el modal
    sesiones: [],

    snackbar: false,
    snackbarText: '',
    timeout: 3000,

    tipoServicio: '',
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  created() {
    this.fetchVentas().then(() => {
      this.fetchCitas();
    }).catch(error => {
      alert.error('Error al obtener datos:', error);
    });
  },


  methods: {

    changeView(view) {
      this.type = view;
    },

    viewDays({ date }) {
      this.focus = date;
      this.type = 'day';
    },

    formatDateToFullString(dateString) {
      const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
      const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

      const date = new Date(dateString);

      const dayOfWeek = daysOfWeek[date.getDay()];
      const day = date.getDate().toString().padStart(2, '0');
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${dayOfWeek} ${day} de ${month} del ${year}`;
    },
    getItemStyle(event) {
      return {
        backgroundColor: event.asistio === 1 ? '#d0d0d0' : 'white', // Color más oscuro si asistio === 1
        borderRadius: '4px', // Opcional, para suavizar las esquinas
        marginBottom: '10px' // Opcional, para espaciar los elementos
      };
    },

    async fetchVentas() {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/sesiones`);
        const data = response.data.body;
        if (Array.isArray(data)) {
          this.sesiones = data.map(sesion => ({
            idSesion: sesion.idSesion,
            idCita: sesion.idCita,
            fecha: this.formatearFecha(sesion.fecha),
            Hora: sesion.Hora,
            createdBy: sesion.createdBy,
            createdDate: sesion.createdDate,
            asistio: sesion.asistio,
            tipoServicio: sesion.tipoServicio,
          }));
        } else {
          this.snackbarText = 'La respuesta de la API no es válida';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener las sesiones';
        this.snackbar = true;
      }
    },

    formatearFecha(fecha) {
      const padZero = (number) => (number < 10 ? '0' : '') + number;
      try {
        // Asegúrate de que 'fecha' es una cadena de texto o un objeto Date
        let date;
        if (typeof fecha === 'string') {
          date = new Date(fecha);
        } else if (fecha instanceof Date) {
          date = fecha;
        } else {
          return '';
        }

        return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
      } catch (error) {
        error
        this.snackbarText = 'Error al formatear la fecha';
        this.snackbar = true;
        return '';
      }
    },


    fetchCitas() {
      axios
        .get(`https://${API_HOST}/api/vista/ventas/`)
        .then((response) => {
          const data = response.data.body;
          if (Array.isArray(data)) {
            this.events = data.reduce((acc, cita) => {
              const sesiones = this.sesiones.filter((sesion) => sesion.idCita === cita.idCita);
              return acc.concat(
                sesiones.map((sesion) => {
                  const startDate = new Date(sesion.fecha);
                  startDate.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset()); // Ajusta la fecha a UTC

                  const startTime = sesion.Hora.split(':');
                  startDate.setHours(startTime[0]);
                  startDate.setMinutes(startTime[1]);

                  // Verifica si startDate es una fecha válida antes de continuar
                  if (isNaN(startDate.getTime())) {
                    console.error('Fecha inválida:', sesion.fecha);
                    return;
                  }

                  // Crear la fecha de fin (endDate) basada en el tipo de servicio
                  const endDate = new Date(startDate);
                  if (sesion.tipoServicio === "NUT" || cita.nombre_servicio === "CONSULTA-NUTRICIONAL") {
                    // Si es NUT o CONSULTA-NUTRICIONAL, la duración es de 30 minutos
                    endDate.setMinutes(startDate.getMinutes() + 30);
                  } else {
                    // Para otros tipos de servicio, la duración es de 1 hora
                    endDate.setHours(startDate.getHours() + 1);
                  }

                  // Ajusta createdDate para que coincida con la hora local
                  let createdDate = new Date(sesion.createdDate);
                  createdDate.setMinutes(createdDate.getMinutes() + createdDate.getTimezoneOffset()); // Ajusta a UTC
                  createdDate.setHours(createdDate.getHours()); // Ajusta la hora si es necesario

                  // Asegurarse de que las fechas estén en el formato correcto
                  const startFormatted = this.formatearFecha(startDate);
                  const endFormatted = this.formatearFecha(endDate);
                  const createdFormatted = this.formatearFecha(createdDate);

                  if (!startFormatted || !endFormatted) {
                    console.error('Fecha mal formateada:', startDate, endDate);
                    return;
                  }

                  if (cita.nombre_servicio === 'NUT+TSHAPE') {
                    this.tipoServicio = sesion.tipoServicio;
                  } else {
                    this.tipoServicio = '-';
                  }

                  return {
                    id: `${cita.idCita}-${sesion.idSesion}`,
                    name: `${cita.nombre_cliente} ${cita.apellido_cliente}`,
                    start: startFormatted,
                    end: endFormatted,
                    color: this.getColorByServicio(cita.nombre_servicio),
                    details: `${cita.nombre_servicio} (${this.tipoServicio})`,
                    hora: sesion.Hora,
                    createdBy: sesion.createdBy,
                    createdDate: createdFormatted,
                    asistio: sesion.asistio,
                  };
                }).filter(Boolean) // Filtra los valores inválidos
              );
            }, []);
          } else {
            this.snackbarText = 'La respuesta de la API no es una lista';
            this.snackbar = true;
          }
        })
        .catch((error) => {
          error
          this.snackbarText = 'Error al obtener las citas';
          this.snackbar = true;
        });
    },




    getColorByServicio(nombre_servicio) {
      const primeraPalabra = nombre_servicio.split(' ')[0].toUpperCase();
      switch (primeraPalabra) {
        case "CONSULTA-NUTRICIONAL":
          return "#35CDCD";
        case "T-SHAPE":
          return "#c8c826";
        case "SLIM-UP":
          return "#78b817";
        case "FACIALES":
          return "#3af52e";
        case "DEPILACION":
          return "#f52e2e";
        case "FITPASS":
          return "#f52ea1";
        case "NUT+TSHAPE":
          return "#0b57f7";
        case "ULTRAFORMER":
          return "#f7870b";
        case "VALORACION":
          return "#8b00f6";
        default:
          return "#b9b9b9";
      }
    },

    viewDay({ date }) {
      //this.focus = date;
      this.openMoreModal(date); // Filtrar y mostrar los eventos de esa fecha
    },


    getEventColor(event) {
      return event.asistio === 1 ? 'grey' : event.color;
    },

    setToday() {
      this.focus = "";
      this.type = 'month';
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    openMoreModal(date) {

      // Convertir la fecha seleccionada a objeto Date para manipular correctamente
      const selectedDate = new Date(date);
      selectedDate.setHours(0, 0, 0, 0); // Asegurarse de que el tiempo esté en medianoche
      // Agregar un día a la fecha seleccionada
      const nextDayDate = new Date(selectedDate);
      nextDayDate.setDate(nextDayDate.getDate() + 1); // Agregar un día
      // Actualizar la fecha formateada para el modal
      this.formattedDate = this.formatDateToFullString(nextDayDate);
      // Filtrar eventos para el día siguiente
      this.filteredEvents = this.events.filter(event => {
        // Convertir la fecha de inicio del evento a objeto Date
        const eventDate = new Date(event.start);
        eventDate.setHours(0, 0, 0, 0); // Asegurarse de que el tiempo esté en medianoche
        // Comparar solo las fechas (sin tiempo)
        return eventDate.getTime() === nextDayDate.getTime();
      });

      this.modalOpen = true;
    }

  },
};
</script>


<style>
.etiqueta {
  background-color: #f52e2e;
  margin: 5px;
  padding: 5px 10px;
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  width: 95%;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.etiqueta.GLP {
  background-color: #35CDCD;
}

.etiqueta.TSHAPE {
  background-color: #c8c826;
}

.etiqueta.SLIMUP {
  background-color: #78b817;
}

.etiqueta.FACIALES {
  background-color: #3af52e;
}

.etiqueta.DEPILACION {
  background-color: #f52e2e;
}

.etiqueta.FITPASS {
  background-color: #f52ea1;
}

.etiqueta.GLP_T_SHAPE {
  background-color: #0b57f7;
}

.etiqueta.ULTRAFORMER {
  background-color: #f7870b;
}

.etiqueta.VALORACION {
  background-color: #8b00f6;
}

.etiqueta.SESION {
  background-color: #b0b0b0;
  color: #ffffff;
}

h3 {
  text-align: center;
  color: black;
  font-family: arial, sans-serif;
  font-size: 22px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 1px;
}
</style>
