<template>
    <v-app>
        <v-container>
            <v-layout>
                <v-app-bar app color="#35CDCD" height="65px">
                    <div class="d-flex align-center">
                        <v-img max-height="50" contain></v-img>
                        <v-toolbar-title class="text-h6 ml-2"></v-toolbar-title>
                    </div>
                </v-app-bar>
            </v-layout>
        </v-container>

        <v-container class="body fluid">
            <v-row justify="center" class="fill-height">
                <v-col cols="12" md="4">
                    <v-card class="elevation-12 mx-auto pa-4" width="350px"> <!-- Establece un ancho fijo de 350px -->
                        <v-img src="@/assets/log2.png" max-height="65" contain></v-img>
                        <v-card-title class="title headline text-center justify-center pa-4"></v-card-title>
                        <!-- <v-img src="@/assets/login.jpg" max-height="45" color="#000" contain></v-img> -->
                        <v-card-text>
                            <v-form @submit.prevent="login" ref="form">
                                <v-text-field v-model="usuario" label="Usuario" required outlined
                                    color="#35CDCD"></v-text-field>
                                <v-text-field v-model="password" label="Contraseña" type="password" required outlined
                                    color="#35CDCD"></v-text-field>
                                <v-btn type="submit" color="#35CDCD" dark block large class="mt-4">Ingresar</v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <Alerta ref="alert" title="Error" message="Usuario o Contraseña incorrecta"></Alerta>
    </v-app>
</template>

<script>
import apiClient from 'axios';
import Alerta from '@/components/Alerta.vue';
import { API_HOST } from '../utils/constants.js';

export default {
    name: 'InicioSesionView',
    components: {
        Alerta
    },
    data() {
        return {
            usuario: '',
            password: '',
            infoUser: {
                id: 0,
                usuario: "",
                password: "",
                rol: ""
            },
            role: "",
            use: "",
        };
    },


    methods: {
        async login() {
            try {
                // Realiza la solicitud de autenticación
                const response = await apiClient.post(`https://${API_HOST}/api/auth/login`, {
                    usuario: this.usuario,
                    password: this.password
                });

                // Guarda el token en localStorage
                const { token } = response.data.body;
                if (!token) {
                    throw new Error('Token no encontrado en la respuesta');
                }
                sessionStorage.setItem('token', token);

                // Realiza la solicitud para obtener la información del usuario usando el token
                const userInfoResponse = await apiClient.get(`https://${API_HOST}/api/auth/login/${this.usuario}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const userData = userInfoResponse.data.body[0];
                sessionStorage.setItem('user', userData.usuario);
                sessionStorage.setItem('role', userData.rol);

                // Redirige a la vista de clientes
                this.$router.push('/vista/clientes');

            } catch (error) {
                // Manejo del error y actualización del mensaje de error
                if (error.response) {
                    // El error es una respuesta HTTP con status
                    if (error.response.status === 500) {
                        this.errorMessage = "El usuario o la contraseña no existen. Verifica los datos de usuario.";
                    } else {
                        this.errorMessage = "Ocurrió un error inesperado. Por favor, intenta de nuevo más tarde.";
                    }
                } else {
                    // El error es un problema de red o configuración
                    this.errorMessage = "No se pudo conectar con el servidor. Inténtalo de nuevo más tarde.";
                }
                // Mostrar el mensaje de error al usuario
                this.$refs.form.resetValidation();
                this.$refs.alert.dialog = true;
            }
        }
    }
};
</script>


<style scoped>
.fill-height {
    min-height: 100vh;
    display: flex;
}
</style>