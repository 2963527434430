<template>
  <v-app>
    <v-row class="mx-0 mt-0">
      <!-- TABLA DE Gastos -->
      <v-col cols="12" md="12">
        <v-card class="elevation-10">
          <v-card-title>
            <h2>Inventario</h2>
            <v-spacer></v-spacer>
            <!-- Parte de búsqueda de gastos -->
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" color="#35CDCD" outlined
              hide-details>
            </v-text-field>
            <v-spacer></v-spacer>
            <v-col>
              <div class="d-flex justify-center mb-0 mt-0">
                <!-- Diálogo 2 -->
                <v-dialog class="mt-10" v-model="dialog2" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="#35CDCD" dark v-bind="attrs" v-on="on">
                      Agregar Nuevo
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Agregar Producto Nuevo</span>
                    </v-card-title>

                    <v-container dense>
                      <form @submit.prevent="guardarProducto">
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="12">
                              <v-text-field type="text" color="#35CDCD" label="Nombre" outlined id="nombre"
                                v-model="nombre" required>
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field type="number" color="#35CDCD" label="Cantidad" outlined id="cantidad"
                                min="1" max="20000000" v-model="cantidad" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field type="number" color="#35CDCD" label="Precio" outlined id="precio" min="1"
                                max="20000000" v-model="precio" required></v-text-field>
                            </v-col>
                          </v-row>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red darken-1" text @click="dialog2 = false">
                              Cancelar
                            </v-btn>
                            <v-btn color="#35CDCD" type="submit" text> Guardar
                            </v-btn>
                          </v-card-actions>
                        </v-container>
                      </form>
                    </v-container>
                    <!-- Dialogo para mostrar alerta -->
                    <v-dialog v-model="alertaformulario" max-width="400">
                      <v-card>
                        <v-card-text class="text-center pa-4">
                          <span class="headline">¡Alerta!</span>
                          <div style="margin-top: 30px;"> <!-- Espacio adicional entre el span y el párrafo -->
                            <p>Por favor, complete todos los campos obligatorios.</p>
                          </div>
                          <v-card-actions class="justify-center">
                            <v-btn color="#35CDCD" text @click="alertaformulario = false">Entendido</v-btn>
                          </v-card-actions>
                        </v-card-text>
                      </v-card>
                    </v-dialog>

                  </v-card>
                </v-dialog>
              </div>
            </v-col>
            <!-- Aquí termina búsqueda de gastos -->
          </v-card-title>
          <!-- Tabla de CRUD para gastos -->
          <v-data-table :headers="filteredHeaders" :items="filteredInventarios" :search="search">
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon size="25px" color="#35CDCD" class="mx-1" @click.stop="editItem(item)" item-key="idProducto">
                mdi-pencil
              </v-icon>
            </template>

            <template v-slot:[`item.details`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon size="25px" color="#35CDCD">
                      mdi-account-multiple-outline
                    </v-icon>
                  </span>
                </template>
                <span>
                  Creado por: {{ item.createdBy || 'N/A' }} el {{ formatDate(item.createdDate) || 'N/A' }}
                  <br>
                  Última modificación por: {{ item.lastModifiedBy || 'N/A' }} el {{
                    formatDate(item.lastModifiedDate) || 'N/A' }}
                </span>
              </v-tooltip>
            </template>


          </v-data-table>
          <!-- Fin del CRUD -->


          <v-dialog v-model="dialogEdit" max-width="800">
            <v-card>
              <v-card-title color="#35CDCD">
                <span class="text-h5" color="#35CDCD">Editar Producto</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field type="text" color="#35CDCD" label="Nombre" outlined id="nombre"
                        v-model="infoProducto.nombre">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field type="number" color="#35CDCD" label="Cantidad" outlined id="cantidad" min="1"
                        max="20000000" v-model="infoProducto.cantidad"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field type="number" color="#35CDCD" label="Precio" outlined id="precio" min="1"
                        max="20000000" v-model="infoProducto.precio"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>


                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="dialogEdit = false">
                    Cerrar
                  </v-btn>
                  <v-btn type="submit" color="#35CDCD" text @click="fetchsaveItem">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card-text>

              <v-spacer></v-spacer>
              <!-- Dialogo para mostrar alerta -->
              <v-dialog v-model="alertaformulario" max-width="400">
                <v-card>
                  <v-card-text class="text-center pa-4">
                    <span class="headline">¡Alerta!</span>
                    <div style="margin-top: 30px;"> <!-- Espacio adicional entre el span y el párrafo -->
                      <p>Por favor, complete todos los campos obligatorios.</p>
                    </div>
                    <v-card-actions class="justify-center">
                      <v-btn color="#35CDCD" text @click="alertaformulario = false">Entendido</v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card>
          </v-dialog>



        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
</template>

<script>
import axios from '../axios';
import { API_HOST } from '../utils/constants.js';

export default {
  name: 'GastosView',
  data() {
    return {
      dialog: false,
      dialog1: false,
      dialog2: false,
      opcionSubMenu1: null,
      opcionSubMenu2: null,
      opcionSubMenu3: null,
      opcionSubMenu4: null,
      opcionSeleccionada: null,
      alertaformulario: false,
      opcionSubMenu: null,
      opcionesModal: ["Opción 1", "Opción 2"], // Ejemplo de opciones para v-select
      cantidadProductos: null,
      modoDialogo: 'listaPedidos', // 'listaPedidos' o 'nuevoPedido'
      itemsglp: [
        { title: "20", value: '1' },
        { title: "40", value: '2' },
        { title: "60", value: '3' }
      ],
      itemsestuche: [
        { title: "1", value: '4' },
      ],
      itemscrema: [
        { title: "1", value: '5' },
        { title: "4", value: '6' },

      ],
      itemsparches: [
        { title: "10", value: '7' },
        { title: "20", value: '8' },
        { title: "30", value: '9' },
        { title: "40", value: '10' },
        { title: "50", value: '11' },
        { title: "60", value: '12' },
        { title: "70", value: '13' },
        { title: "80", value: '14' },
        { title: "90", value: '15' },
        { title: "100", value: '16' }

      ],
      pedidos: [
        { material: 'GLP', cantidad: 10 },
        { material: 'Estuches', cantidad: 5 },
        { material: 'Cremas', cantidad: 10 },
        { material: 'Parches', cantidad: 5 },
      ],
      nuevoPedido: {
        material: '',
        valor: 0,
        cantidad: 0,
        descripcion: ''
      },
      nombre: '',
      cantidad: '',
      precio: '',
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      search: "",
      inventarios: [],
      snackbar: false,
      snackbarText: '',
      timeout: 3000,
      idProducto: '',
      user: null,
      role: null,

      filteredHeaders: [],

      dialogEdit: false,
      infoProducto: {
        nombre: '',
        cantidad: '',
        precio: '',
      },
    };
  },

  methods: {
    //FORMATEO DE FECHA PARA DETALLES
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'N/A'; // Verifica si la fecha es inválida
      return date.toISOString().split('T')[0]; // 'YYYY-MM-DD'
    },

    //MOSTRAR DATOS DE INVENTARIO
    fetchInventarios() {
      axios.get(`https://${API_HOST}/api/vista/inventarios`)
        .then(response => {
          let data = response.data.body;
          if (Array.isArray(data)) {
            this.inventarios = data;
          } else {
            this.snackbarText = 'La respuesta de la API no es válida';
            this.snackbar = true;
          }
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al obtener los productos';
          this.snackbar = true;
        });
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    resetSubMenus() {
      this.idProducto = null;
    },

    //AGREGAR UN PRODUCTO NUEVO AL INVENTARIO
    async guardarProducto() {
      if (!this.nombre || !this.cantidad || !this.precio) {
        this.alertaformulario = true;
        return;
      }
      try {
        await axios.post(`https://${API_HOST}/api/vista/inventarios`, {
          nombre: this.nombre,
          cantidad: this.cantidad,
          precio: this.precio,
          createdBy: sessionStorage.getItem('user'),
          createdDate: new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        });
        this.dialog2 = false;
        // Limpia el formulario después de enviarlo
        this.fetchInventarios();
        this.nombre = '';
        this.cantidad = '';
        this.precio = '';
        this.snackbarText = 'Producto agregado exitosamente';
        this.snackbar = true;
      } catch (error) {
        error
        this.snackbarText = 'Error al enviar los datos';
        this.snackbar = true;
      }
    },

    //AGREGAR UN PRODUCTO EXISTENTE
    async actualizarInventario() {
      if (!this.idProducto || !this.cantidad) {
        this.alertaformulario = true;
        alert("NO se puede");
        return;
      } try {
        const response = await axios.post(`https://${API_HOST}/api/vista/inventarios`, {
          idProducto: this.idProducto,
          cantidad: this.cantidad
        });
        this.idProducto = '';
        this.cantidad = '';
        this.mensaje = response.data;
        this.error = false;
      } catch (error) {
        error
        this.snackbarText = 'Error al actualizar el inventario';
        this.snackbar = true;
      }
    },

    // FILTRAR ENCABEZADOS SEGÚN EL ROL DEL USUARIO
    applyRoleBasedHeaders() {
      const userRole = sessionStorage.getItem('role');
      this.filteredHeaders = this.headers.filter(header => {
        if (userRole === 'standard' && header.value === 'actions') {
          return false;
        }
        return true;
      });
    },

    //FUNCION PARA EDITAR PRODUCTOS
    editItem(item) {
      this.productoAEditar = item.idProducto;
      this.dialogEdit = true;
      this.fetchProducto(this.productoAEditar);
    },

    //FUNCION PARA RECUPERAR LOS DATOS DE UN PRODUCTO PARA EDITARLO
    async fetchProducto(idProducto) {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/inventarios/${idProducto}`);
        if (response.data && response.data.body && typeof response.data.body === 'object') {
          this.infoProducto = response.data.body;
        } else {
          this.snackbarText = 'La respuesta del servidor no contiene datos válidos';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener datos del producto';
        this.snackbar = true;
      }
    },

    //FUNCION PARA EDITAR CLIENTES
    fetchsaveItem() {
      if (!this.infoProducto.nombre || !this.infoProducto.cantidad || !this.infoProducto.precio) {
        this.alertaformulario = true;
        return;
      }

      axios.put(`https://${API_HOST}/api/vista/inventarios/${this.productoAEditar}`, {
        nombre: this.infoProducto.nombre,
        cantidad: this.infoProducto.cantidad,
        precio: this.infoProducto.precio,

        lastModifiedBy: sessionStorage.getItem('user'),
        lastModifiedDate: new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      })
        .then(() => {
          this.fetchInventarios();
          this.dialogEdit = false;
          this.productoAEditar = null;
          this.snackbarText = 'Producto editado exitosamente';
          this.snackbar = true;
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al editar el cliente';
          this.snackbar = true;
        });
    },
  },

  watch: {
    opcionSeleccionada(newOption) {
      // Resetear opcionesModal y cargar las opciones según el radio button seleccionado
      this.opcionesModal = this.obtenerOpciones(newOption);
    }
  },

  created() {
    // Fetch user and role from local storage
    const storedUser = sessionStorage.getItem('user');
    const storedRole = sessionStorage.getItem('role');

    if (storedUser && storedRole) {
      this.user = storedUser;
      this.role = storedRole;
    }

    if (this.role === 'standard') {
      this.fetchInventarios();
    } else {
      this.fetchInventarios();
    }


    this.applyRoleBasedHeaders();
  },

  computed: {
    headers() {
      return [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Cantidad', value: 'cantidad' },
        { text: 'Precio', value: 'precio' },
        { text: "Editar", value: "actions", sortable: false },
        { text: 'Detalles', value: 'details', sortable: false },
      ];
    },

    filteredInventarios() {
      if (this.role === 'standard') {
        return this.inventarios.filter(item => !item.nombre.startsWith('GLP'));
      }
      return this.inventarios;
    },
  },
};
</script>

<style>
/* Estilos específicos de ClientesView */
</style>
