<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" @input="onDialogClose">
      <v-card>

        <v-toolbar dark color="#35CDCD">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Clientes - </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list three-line subheader>

          <v-row class="mx-3 my-5">
            <v-row class="mx-3 my-5">
              <!-- Primera tarjeta -->
              <v-col cols="12" md="3">
                <v-card>
                  <v-card-title color="#35CDCD">
                    <h4 class="mx-2 mt-2">Acciones</h4>
                  </v-card-title>
                  <v-list dense>
                    <!-- Itera sobre las opciones excluyendo las dos últimas -->
                    <v-list-item v-for="(opcion, index) in opciones.slice(0, -2)" :key="index"
                      @click="seleccionarOpcion2(opcion)">
                      <v-list-item-title>{{ opcion }}</v-list-item-title>
                    </v-list-item>
                  <!-- </v-list> -->
                  <!-- Agrega espacio vertical entre las dos últimas opciones -->
                  <!-- <v-divider class="mt-5"> hola</v-divider> -->
                  <!-- Itera sobre las dos últimas opciones -->
                  <!-- <v-list dense> -->
                    <v-list-item v-for="(opcion, index) in opciones.slice(-2)" :key="index"
                      @click="seleccionarOpcion2(opcion)">
                      <v-list-item-title>{{ opcion }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>

              <!-- Segunda tarjeta -->
              <v-col cols="12" md="9">
                <v-card>
                  <!-- Mostramos el componente correspondiente según la opción seleccionada -->
                  <perfil v-if="opcionSeleccionada === 'Perfil'" />
                  <valoracion v-if="opcionSeleccionada === 'Valoracion'" />
                  <articulos v-if="opcionSeleccionada === 'Articulos'" />
                  <sesion v-if="opcionSeleccionada === 'Agregar sesion'" />
                </v-card>
              </v-col>
            </v-row>
          </v-row>

        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
//import VentasView from '@/views/VentasView.vue';
import Perfil from '@/views/PerfilView.vue';
import Valoracion from '@/views/ValoracionView.vue';
import Articulos from '@/views/ArticulosView.vue';
import Sesion from '@/views/SesionesView.vue';

export default {

  components: {
    //  VentasView
    Perfil,
    Valoracion,
    Articulos,
    Sesion,
  },
  data() {
    return {
      opciones: ['Perfil', 'Valoracion', 'Articulos', 'Agregar sesion', 'Vender Productos'],
      opcionSeleccionada: null,
      dialog: true, // Asegúrate de que el modal esté abierto al cargar la página
      // Otros datos necesarios para tu modal
    };
  },
  methods: {
    seleccionarOpcion2(opcion) {
      this.opcionSeleccionada = opcion;
    },

  },
};
</script>

<style></style>
