<template>
  <v-app>
    <v-row class="mx-0 mt-0">
      <!-- TABLA DE CORTE -->
      <v-col cols="12" md="">
        <v-card class="elevation-10">
          <v-card-title>
            <h2>Corte</h2>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" color="#35CDCD" outlined label="Buscar"
              hide-details>
            </v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="formatoCorte()" :search="search">
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon size="25px" color="#35CDCD" class="mx-1" @click.stop="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon size="25px" color="red" class="mx-1" @click.stop="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:[`item.fecha`]="{ item }">
              {{ item.fecha }}
            </template>
            <template v-slot:[`item.total`]="{ item }">
              <v-chip :color="getColor(calcularTotal(item))">
                {{ calcularTotal(item) }}
              </v-chip>
            </template>
          </v-data-table>
          <!-- Fin del CRUD -->
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
</template>


<script>
import axios from '../axios';
import { API_HOST } from '../utils/constants.js';

export default {
  name: 'GastosView',
  data() {
    return {
      dialog: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      search: "",
      cortes: [],
      fechaDesdeServidor: [],
      fechaFormateada: [],

      snackbar: false,
      snackbarText: '',
      timeout: 3000,
    };
  },

  methods: {
    formatearFecha(fechaDesdeServidor) {
      try {
        const fechasFormateadas = fechaDesdeServidor.map(fecha => fecha.split('T')[0]);
        this.fechaFormateada = fechasFormateadas;
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener los ventas';
        this.snackbar = true;
      }
    },

    fetchCorte() {
      axios.get(`https://${API_HOST}/api/vista/cortes`)
        .then(response => {
          let data = response.data.body;
          if (Array.isArray(data)) {
            this.cortes = data;
            this.fechaDesdeServidor = this.cortes.map(corte => corte.fecha);
            this.formatearFecha(this.fechaDesdeServidor);
          } else {
            this.snackbarText = 'La respuesta de la API no es válida';
            this.snackbar = true;
          }
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al obtener los cortes';
          this.snackbar = true;
        });

    },

    formatoCorte() {
      return this.cortes.map(corte => ({
        ...corte,
        fecha: corte.fecha.substring(0, 10)
      }));
    },

    calcularTotal(corte) {
      const efectivo = Number(corte.efectivo) || 0;
      const credito = Number(corte.credito) || 0;
      const debito = Number(corte.debito) || 0;
      const transferencia = Number(corte.transferencia) || 0;
      const gastos = Number(corte.gastos) || 0;

      return efectivo + credito + debito + transferencia - gastos;
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    getColor(total) {
      if (total > 0) {
        return 'green';
      } else {
        return 'red';
      }
    },

  },
  created() {
    this.fetchCorte();
  },


  computed: {
    headers() {
      return [
        { text: 'Fecha', value: 'fecha' },
        { text: 'Efectivo', value: 'efectivo' },
        { text: 'Credito', value: 'credito' },
        { text: 'Debito', value: 'debito' },
        { text: 'Transferencia', value: 'transferencia' },
        { text: 'Gastos', value: 'gastos' },
        { text: 'Total', value: 'total' },
      ];
    },
  },

  filters: {
    formatDate(value) {
      if (!value) return '';
      const date = new Date(value);
      const day = date.getDate();
      const month = date.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por eso sumamos 1
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  },


};
</script>

<style>
/* Estilos específicos de ClientesView */
</style>