import Vue from "vue";
import VueRouter from "vue-router";
import InicioSesionView from "@/views/InicioSesionView.vue";
import AgendaView from "@/views/AgendaView.vue";
import ClientesView from "@/views/ClientesView.vue";
import CorteView from "@/views/CorteView.vue";
import InventarioView from "@/views/InventarioView.vue";
import VentasView from "@/views/VentasView.vue";
import GastosView from "@/views/GastosView.vue";
import ComisionesView from "@/views/ComisionesView.vue";
import PrincipalView from "@/layouts/PrincipalView";
import CerrarSesion from "@/components/CerrarSesion.vue";
import Perfil from "@/components/PerfilCliente.vue";
import PerfilCliente from "@/views/PerfilView.vue";
import Valoracion from "@/views/ValoracionView.vue";
import Articulos from "@/views/ArticulosView.vue";
import Sesion from "@/views/SesionesView.vue";
import AgregarView from "@/views/AgregarView.vue";
import AgregarSesion from "@/views/AgregarSesionView.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "InicioSesion",
    component: InicioSesionView,
  },
  { path: "/perfil", name: "perfil", component: Perfil },
  { path: "/perfil2", name: "perfil2", component: PerfilCliente },
  { path: "/valoracion", name: "valoracion", component: Valoracion },
  { path: "/articulos", name: "articulos", component: Articulos },
  { path: "/sesion", name: "sesion", component: Sesion },
  { path: "/agregarsesion", name: "agregarsesion", component: AgregarSesion },
  {
    path: "/vista",
    component: PrincipalView,
    meta: { requiresAuth: true },
    children: [
      {
        path: "clientes",
        name: "Clientes",
        component: ClientesView,
      },
      {
        path: "agenda",
        name: "Agenda",
        component: AgendaView,
      },
      {
        path: "inventario",
        name: "Inventario",
        component: InventarioView,
      },
      {
        path: "ventas",
        name: "Ventas",
        component: VentasView,
      },
      {
        path: "gastos",
        name: "Gastos",
        component: GastosView,
      },
      {
        path: "corte",
        name: "Corte",
        component: CorteView,
      },
      {
        path: "comisiones",
        name: "Comisiones",
        component: ComisionesView,
      },
      {
        path: "cerrarsesion",
        name: "Cerrarsesion",
        component: CerrarSesion,
      },
      {
        path: "agregar",
        name: "Agregar",
        component: AgregarView,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = sessionStorage.getItem("token");
  if (to.path !== "/" && !isAuthenticated) {
    next("/");
  } else {
    next();
  }
});

export default router;
